import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../api';
import TotalValueOverTimeChart from './charts/TotalValueOverTime';
import { printAmountWithBenefits } from "../utils";
import Loader from "./Loader";
import { UserContext } from "../context/UserContext";
import AssetCategoriesTable from "./assets/AssetCategoriesTable";
import AssetCategoriesRepartition from './charts/AssetCategoriesRepartition';  // Import du nouveau composant

function Dashboard({ assetCategories }) {
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalBenefits, setTotalBenefits] = useState(0);
    const [totalBenefitsPercentage, setTotalBenefitsPercentage] = useState(0);
    const [totalValuesOverTime, setTotalValuesOverTime] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { preferences, user, assetCategoriesSummary, getAssetCategoriesSummary } = useContext(UserContext);
    const { hideAmounts } = preferences;
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            navigate('/landing');
            return;
        }

        async function fetchData() {
            try {
                const [
                    totalAmountResponse,
                    totalBenefitsResponse,
                    totalValuesOverTimeResponse,
                ] = await Promise.all([
                    api.get('/total-amount/'),
                    api.get('/total-benefits/'),
                    api.get('/total-values-over-time/'),
                ]);

                getAssetCategoriesSummary()
                setTotalAmount(totalAmountResponse.data);
                setTotalBenefits(totalBenefitsResponse.data.total_benefits);
                setTotalBenefitsPercentage(totalBenefitsResponse.data.total_percents);
                setTotalValuesOverTime(totalValuesOverTimeResponse.data.cumulative_data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données', error);
            } finally {
                setIsLoading(false);
            }
        }

        fetchData();
    }, [navigate, user]);

    if (isLoading) {
        return <Loader />;
    }

    if (Object.keys(assetCategoriesSummary).length === 0) {
        return (
            <div className="flex items-center justify-center h-screen bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white">
                <Link to="/create-portfolio/all" className="bg-yellow-500 text-black text-lg py-2 px-4 rounded shadow hover:bg-yellow-600 transition duration-300 ease-in-out">
                    Créer mon premier portefeuille
                </Link>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <h2 className="text-3xl sm:text-4xl font-bold text-yellow-500 mb-6">
                    Tableau de Bord - {printAmountWithBenefits(totalAmount, totalBenefits, totalBenefitsPercentage, hideAmounts)}
                </h2>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-6">
                    <div className="w-full lg:col-span-2 bg-gray-800 p-4 rounded-lg shadow-lg">
                        <TotalValueOverTimeChart data={totalValuesOverTime} hideAmounts={hideAmounts} />
                    </div>
                    <div className="w-full lg:col-span-1 bg-gray-800 p-4 rounded-lg shadow-lg">
                        <AssetCategoriesRepartition assetCategoriesSummary={assetCategoriesSummary} assetCategories={assetCategories} hideAmounts={hideAmounts}/>
                    </div>
                </div>
                <div className="bg-gray-800 p-4 rounded-lg shadow-lg">
                    <AssetCategoriesTable assetCategoriesSummary={assetCategoriesSummary} assetCategories={assetCategories} hideAmounts={hideAmounts}/>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
