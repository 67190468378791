import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Turnstile } from '@marsidev/react-turnstile';
import api from '../../../api';

const RegisterStep3 = ({ personalInfo, accountInfo }) => {
    const [captchaToken, setCaptchaToken] = useState(null);
    const [reloadCaptcha, setReloadCaptcha] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Ajout de l'état de chargement
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Définir l'état de chargement à vrai
        try {
            const response = await api.post('/register/step2/', { ...personalInfo, ...accountInfo, captcha_token: captchaToken });
            NotificationManager.success('Compte créé avec succès. Vérifiez votre email pour le code de confirmation.');
            localStorage.setItem('user_id', response.data.user_id); // Stocker l'ID de l'utilisateur dans localStorage
            navigate('/email-confirmation');
        } catch (error) {
            NotificationManager.error('Erreur lors de la création du compte');
            console.error('Erreur lors de la création du compte', error);

            if (error.response && error.response.data.error === 'Captcha validation failed') {
                NotificationManager.error('Captcha invalide');
                setReloadCaptcha(true); // Force reload of CAPTCHA
            }
        } finally {
            setIsLoading(false); // Réinitialiser l'état de chargement
        }
    };

    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
        setReloadCaptcha(false); // Reset reload state
    };

    return (
        <div className="w-full max-w-4xl mx-auto p-8 rounded-lg shadow-md">
            <h2 className="text-3xl font-semibold text-center text-yellow-500 mb-4">Validation CAPTCHA</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
                {!reloadCaptcha && (
                    <Turnstile
                        className={"mx-auto"}
                        siteKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                        onSuccess={handleCaptchaChange}
                    />
                )}
                <button
                    type="submit"
                    className="w-full py-3 bg-yellow-500 text-black text-lg font-semibold rounded-lg shadow-md hover:bg-yellow-600 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                    disabled={isLoading} // Désactiver le bouton pendant le chargement
                >
                    {isLoading ? 'Création du compte...' : 'Créer un compte'} {/* Afficher le loader */}
                </button>
            </form>
        </div>
    );
};

export default RegisterStep3;
