import React, { useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../../api';
import { UserContext } from "../../../context/UserContext";
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Switch from 'react-switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const StandardAssetCreate = ( {category, getAssetCategoriesSummary} ) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const [assetName, setAssetName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [quantity, setQuantity] = useState(0);
    const [investedAmount, setInvestedAmount] = useState(0);
    const [totalCurrentValue, setTotalCurrentValue] = useState(0);
    const [useInvestedAmount, setUseInvestedAmount] = useState(true);
    const [unitValue, setUnitValue] = useState(0);
    const [initialBenefitsPercentage, setInitialBenefitsPercentage] = useState(0);

    const handleInvestedAmountChange = (value) => {
        setInvestedAmount(value);
        if (!isNaN(totalCurrentValue) && totalCurrentValue > 0 && value) {
            setInitialBenefitsPercentage(((totalCurrentValue - value) / value * 100));
        }
    };

    const handleBenefitsPercentageChange = (value) => {
        setInitialBenefitsPercentage(value);
        if (!isNaN(totalCurrentValue) && totalCurrentValue > 0 && value) {
            setInvestedAmount((totalCurrentValue / (1 + value / 100)));
        }
    };

    const handleUnitValueChange = (value) => {
        setUnitValue(value);
        setTotalCurrentValue((value * quantity).toFixed(2));
    };

    const handleTotalValueChange = (value) => {
        setTotalCurrentValue(value);
        if (quantity > 0) {
            setUnitValue((value / quantity).toFixed(2));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const payload = {
                portfolio_id: id,
                user: user.id,
                asset_name: assetName,  // Utiliser le nom de l'actif directement
                unit_value: unitValue,
                quantity,  // Ajouter la quantité au payload
                benefitsPercentage: initialBenefitsPercentage,
                transactionType: 'buy',
                type: category
            };

            await api.post('/create-transaction/', payload);

            getAssetCategoriesSummary()

            navigate(`/portfolio-history/${id}`);
            NotificationManager.success('Actif ajouté avec succès');
        } catch (error) {
            NotificationManager.error('Erreur lors de la création de l\'actif');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <label className="block text-gray-300 mb-2" htmlFor="assetName">
                    Nom de l'actif
                </label>
                <input
                    type="text"
                    id="assetName"
                    className="w-full px-3 py-2 bg-gray-700 text-white rounded-md"
                    value={assetName}
                    onChange={(e) => setAssetName(e.target.value)}
                    placeholder="Entrez le nom de l'actif"
                    required
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-300 mb-2" htmlFor="quantity">
                    Quantité
                </label>
                <input
                    type="text"
                    id="quantity"
                    step="any"
                    className="w-full px-3 py-2 bg-gray-700 text-white rounded-md"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    required
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-300 mb-2" htmlFor="unitValue">
                    Valeur unitaire de l'actif
                </label>
                <input
                    type="text"
                    min="0"
                    step="any"
                    id="unitValue"
                    className="w-full px-3 py-2 bg-gray-700 text-white rounded-md"
                    value={unitValue}
                    onChange={(e) => handleUnitValueChange(e.target.value)}
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-300 mb-2" htmlFor="totalCurrentValue">
                    Montant actuel total (bénéfices inclus)
                </label>
                <input
                    type="text"
                    min="0"
                    step="any"
                    id="totalCurrentValue"
                    className="w-full px-3 py-2 bg-gray-700 text-white rounded-md"
                    value={totalCurrentValue}
                    onChange={(e) => handleTotalValueChange(e.target.value)}
                />
            </div>

            <div className="mb-4">
                <div className="flex items-center">
                    <span className="text-white mr-3">Pourcentage de bénéfices initial</span>
                    <Switch
                        onChange={setUseInvestedAmount}
                        checked={useInvestedAmount}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onColor="#FFA500"
                        offColor="#444"
                        handleDiameter={20}
                        height={20}
                        width={48}
                    />
                    <span className="text-white ml-3">Montant investi</span>
                </div>
            </div>

            <div className="mb-4 flex flex-col sm:flex-row justify-between gap-4">
                {useInvestedAmount ? (
                    <div className="flex-1">
                        <label className="block text-gray-300 mb-2" htmlFor="investedAmount">
                            Montant investi
                        </label>
                        <input
                            type="text"
                            id="investedAmount"
                            step="0.01"
                            className="w-full px-3 py-2 bg-gray-700 text-white rounded-md"
                            value={investedAmount}
                            onChange={(e) => handleInvestedAmountChange(e.target.value)}
                            required
                        />
                    </div>
                ) : (
                    <div className="flex-1">
                        <label className="block text-gray-300 mb-2" htmlFor="initialBenefitsPercentage">
                            Pourcentage de bénéfices initial
                        </label>
                        <input
                            type="text"
                            id="initialBenefitsPercentage"
                            className="w-full px-4 py-2 bg-gray-800 border rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                            value={initialBenefitsPercentage}
                            onChange={(e) => handleBenefitsPercentageChange(e.target.value)}
                            required
                        />
                    </div>
                )}
            </div>

            <div className="flex justify-end">
                <button
                    type="submit"
                    className="flex items-center bg-green-600 text-white text-lg py-2 px-4 rounded shadow hover:bg-green-700 transition duration-300 ease-in-out"
                    disabled={isLoading}
                >
                    <FontAwesomeIcon icon={faPlus} className="mr-2"/>
                    {isLoading ? 'En cours...' : 'Ajouter'}
                </button>
            </div>
        </form>
    );
};

export default StandardAssetCreate;
