import React from 'react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
    return (
        <div className="min-h-screen flex flex-col items-center bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white">
            <div className="max-w-3xl w-full bg-gradient-to-r from-gray-900 via-gray-800 to-black p-8 rounded-lg shadow-lg border border-yellow-400 text-center mt-16 mb-8">
                <h2 className="text-4xl font-bold text-yellow-500 mb-6">Bienvenue sur Stonqle</h2>
                <p className="text-lg text-gray-300 mb-6">
                    Créez et gérez vos actifs avec Stonqle. Connectez-vous ou créez un compte pour commencer.
                </p>
                <div>
                    <Link to="/login" className="bg-yellow-500 text-black text-lg py-2 px-4 rounded shadow hover:bg-yellow-600 transition duration-300 ease-in-out mr-4">
                        Se connecter
                    </Link>
                    <Link to="/register" className="bg-yellow-500 text-black text-lg py-2 px-4 rounded shadow hover:bg-yellow-600 transition duration-300 ease-in-out">
                        Créer un compte
                    </Link>
                </div>
            </div>
            <div className="max-w-5xl w-full bg-gray-800 p-8 rounded-lg shadow-lg border border-gray-700 mb-8">
                <h3 className="text-3xl font-semibold text-yellow-500 mb-4">Pourquoi choisir Stonqle ?</h3>
                <ul className="text-lg text-gray-300 mb-6 space-y-3">
                    <li>🔒 Sécurité de pointe pour protéger vos données et investissements</li>
                    <li>📊 Outils d'analyse et de reporting avancés</li>
                    <li>🌍 Accès global à partir de n'importe quel appareil</li>
                    <li>📈 Plateforme de gestion d'actifs innovante</li>
                </ul>
            </div>
            <div className="max-w-5xl w-full bg-gray-800 p-8 rounded-lg shadow-lg border border-gray-700 mb-8">
                <h3 className="text-3xl font-semibold text-yellow-500 mb-4">Témoignages</h3>
                <div className="text-lg text-gray-300 mb-6">
                    <p className="mb-4">"Stonqle a révolutionné ma façon de gérer mes investissements. Interface intuitive et support exceptionnel!" - <span className="text-yellow-500">Alexandre P.</span></p>
                    <p>"Une plateforme fiable et riche en fonctionnalités. Je la recommande vivement." - <span className="text-yellow-500">Marie D.</span></p>
                </div>
            </div>
            <div className="max-w-5xl w-full bg-gray-800 p-8 rounded-lg shadow-lg border border-gray-700 mb-8">
                <h3 className="text-3xl font-semibold text-yellow-500 mb-4">En savoir plus</h3>
                <p className="text-lg text-gray-300 mb-6">
                    Stonqle est conçu pour vous aider à gérer vos investissements de manière efficace et sécurisée. Avec nos outils avancés et nos conseils personnalisés, vous pouvez optimiser vos portefeuilles et atteindre vos objectifs financiers plus rapidement.
                </p>
                <div className="flex justify-center">
                    <Link to="/features" className="bg-yellow-500 text-black text-lg py-2 px-4 rounded shadow hover:bg-yellow-600 transition duration-300 ease-in-out">
                        Découvrir les fonctionnalités
                    </Link>
                </div>
            </div>
            <div className="max-w-5xl w-full bg-gray-800 p-8 rounded-lg shadow-lg border border-gray-700 mb-16">
                <h3 className="text-3xl font-semibold text-yellow-500 mb-4">Questions</h3>
                <p className="text-lg text-gray-300 mb-6">
                    Vous avez des questions ? N'hésitez pas à nous contacter à <a href="mailto:contact@pixlesolutions.com" className="text-yellow-500">contact@pixlesolutions.com</a>.
                </p>
            </div>
        </div>
    );
};

export default LandingPage;
