import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { printAmountWithBenefits } from "../../utils";
import TotalValueOverTime from './../charts/TotalValueOverTime';
import { NotificationManager } from 'react-notifications';
import api from '../../api';

const PortfolioHeader = ({ id, name, description, value, benefits, benefitsPercentage, hideAmounts, image, history, categoryLabel, profitsHistory }) => {
    const [previewImage, setPreviewImage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setPreviewImage(URL.createObjectURL(file));

            const formData = new FormData();
            formData.append('image', file);

            setIsSubmitting(true);
            try {
                const response = await api.put(`/portfolios/${id}/update-image/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setPreviewImage(response.data.image);
                NotificationManager.success('Image du portefeuille mise à jour avec succès !', 'Succès');
            } catch (error) {
                console.error('Erreur lors de la mise à jour de l\'image', error);
                NotificationManager.error('Une erreur est survenue lors de la mise à jour de l\'image du portefeuille.', 'Erreur');
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    const handleImageClick = () => {
        document.getElementById('portfolioImageInput').click();
    };

    return (
        <div className="flex flex-col sm:flex-row items-center justify-between">
            <div className="flex flex-col items-center sm:w-1/3 justify-center">
                <div className="relative mb-4">
                    {image || previewImage ? (
                        <img src={previewImage || image} alt={name} className="h-24 w-24 sm:h-32 sm:w-32 object-cover rounded-full shadow-md"/>
                    ) : (
                        <div className="h-24 w-24 sm:h-32 sm:w-32 flex items-center justify-center bg-gray-700 rounded-full shadow-md">
                            <span className="text-gray-500 text-center">No Image</span>
                        </div>
                    )}
                    <div
                        className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 text-white opacity-0 hover:opacity-100 rounded-full cursor-pointer"
                        onClick={handleImageClick}
                    >
                        <FontAwesomeIcon icon={faEdit} />
                    </div>
                    <input
                        type="file"
                        id="portfolioImageInput"
                        accept="image/*"
                        onChange={handleImageChange}
                        className="hidden"
                    />
                </div>
                <div className="text-center sm:text-left">
                    <span className="text-white text-2xl sm:text-3xl font-bold">{name}</span>
                    <span className="text-gray-300 mt-2 block text-sm sm:text-base">{categoryLabel}</span>
                    <span className="text-gray-300 mt-2 block text-sm sm:text-base">{description}</span>
                    <span className="text-yellow-500 mt-2 block text-lg sm:text-xl">{printAmountWithBenefits(value, benefits, benefitsPercentage, hideAmounts)}</span>
                </div>
            </div>
            <div className="w-full sm:w-2/3 mt-8 sm:mt-0 sm:ml-8">
                <TotalValueOverTime
                    data={history}
                    hideAmounts={hideAmounts}
                    profitsHistory={profitsHistory}
                    className="w-full h-32 sm:h-40 lg:h-48"
                />
            </div>

        </div>
    );
};

export default PortfolioHeader;
