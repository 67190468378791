import axios from 'axios';
import {jwtDecode} from 'jwt-decode';

const api = axios.create({
    baseURL: process.env.REACT_APP_ENVIRONMENT === 'development' ? process.env.REACT_APP_API_BASE_URL_DEVELOPMENT : process.env.REACT_APP_API_BASE_URL,
    withCredentials: true,  // Permettre les cookies
});

api.interceptors.request.use(async (config) => {
    let accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');

    if (accessToken) {
        try {
            const tokenData = jwtDecode(accessToken);
            const expiryTime = tokenData.exp * 1000;
            const currentTime = Date.now();

            if (currentTime > expiryTime && refreshToken) {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/token/refresh/`, {
                    refresh: refreshToken,
                });

                accessToken = response.data.access;
                localStorage.setItem('access_token', accessToken);
                config.headers['Authorization'] = `Bearer ${accessToken}`;
            } else {
                config.headers['Authorization'] = `Bearer ${accessToken}`;
            }
        } catch (error) {
            console.error('Error refreshing access token:', error);
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            window.location.href = '/login';
            return Promise.reject(error);
        }
    }

    const csrftoken = getCookie('csrftoken');
    if (csrftoken) {
        config.headers['X-CSRFToken'] = csrftoken;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

// Fonction pour obtenir le jeton CSRF
function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export default api;
