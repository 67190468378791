import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Turnstile } from '@marsidev/react-turnstile';
import api from '../../api';
import { UserContext } from '../../context/UserContext';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [captchaToken, setCaptchaToken] = useState(null);
    const [reloadCaptcha, setReloadCaptcha] = useState(false);
    const [errors, setErrors] = useState({});
    const [loginError, setLoginError] = useState(false);
    const { setUser } = useContext(UserContext);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = {};

        if (!username) validationErrors.username = 'Nom d\'utilisateur requis';
        if (!password) validationErrors.password = 'Mot de passe requis';
        if (!captchaToken) validationErrors.captcha = 'Captcha requis';

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            NotificationManager.error('Veuillez corriger les erreurs ci-dessus');
            return;
        }

        try {
            const response = await api.post('/login/', { username, password, captchaToken });
            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);
            api.defaults.headers['Authorization'] = 'Bearer ' + response.data.access;

            // Fetch the user data after login
            const userResponse = await api.get('/current-user/');
            setUser(userResponse.data);
            localStorage.setItem('user', JSON.stringify(userResponse.data));

            NotificationManager.success('Connexion réussie');
            navigate('/');
        } catch (error) {
            if (error.response.data.detail === 'User account is not active.') {
                localStorage.setItem('user_id', error.response.data.user_id); // Store the user_id in localStorage
                NotificationManager.error('Votre compte n\'est pas encore activé. Veuillez vérifier votre email.');
                navigate('/email-confirmation'); // Redirect to the email confirmation page
                return;
            }

            if (error.response.data.error === 'Captcha validation failed') {
                NotificationManager.error('Captcha invalide');
                handleCaptchaChange(null);
                setReloadCaptcha(true);    // Force reload of CAPTCHA
            }

            setLoginError(true);
            console.error('Erreur lors de la connexion', error);
        }
    };

    useEffect(() => {
        if (reloadCaptcha) {
            setReloadCaptcha(false);
        }
    }, [reloadCaptcha]);

    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
        setErrors((prevErrors) => ({ ...prevErrors, captcha: null }));
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white">
            <div className="max-w-md w-full bg-gradient-to-r from-gray-900 via-gray-800 to-black p-8 rounded-lg shadow-lg border border-yellow-400">
                <h2 className="text-3xl font-semibold text-center text-yellow-500 mb-4">Connexion</h2>
                {loginError && (
                    <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
                        Nom d'utilisateur ou mot de passe incorrect
                    </div>
                )}
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="relative">
                        <label className="block text-yellow-500 text-lg font-bold mb-2" htmlFor="username">
                            Nom d'utilisateur
                        </label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className={`w-full px-4 py-2 bg-gray-800 border rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400 ${errors.username ? 'border-red-500' : 'border-gray-300'}`}
                            required
                        />
                        {errors.username && <p className="text-red-500 text-sm mt-1">{errors.username}</p>}
                    </div>
                    <div className="relative">
                        <label className="block text-yellow-500 text-lg font-bold mb-2" htmlFor="password">
                            Mot de passe
                        </label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className={`w-full px-4 py-2 bg-gray-800 border rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400 ${errors.password ? 'border-red-500' : 'border-gray-300'}`}
                            required
                        />
                        {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
                    </div>
                    {!reloadCaptcha &&
                        <Turnstile
                            theme="dark"
                            className={"mx-auto"}
                            siteKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                            onSuccess={handleCaptchaChange}
                        />
                    }

                    {errors.captcha && <p className="text-red-500 text-sm mt-1">{errors.captcha}</p>}
                    <button
                        type="submit"
                        className="w-full py-3 bg-yellow-500 text-black text-lg font-semibold rounded-lg shadow-md hover:bg-yellow-500 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                    >
                        Se connecter
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
