import React, { useState } from 'react';

const UpdateAssetBenefits = ({ asset, onClose, onUpdate }) => {
    const [benefitsPercentage, setBenefitsPercentage] = useState(parseFloat(asset.benefitsPercentage).toFixed(2));

    const handleSubmit = () => {
        onUpdate(asset.id, benefitsPercentage);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-black bg-opacity-50"></div>
            <div className="bg-gray-900 rounded-lg shadow-lg p-6 z-10 w-full max-w-md mx-auto">
                <h2 className="text-2xl text-white mb-4">Modifier les bénéfices</h2>
                <div className="mb-4">
                    <label className="block text-white mb-2">Quantité</label>
                    <input
                        type="number"
                        className="w-full p-2 rounded bg-gray-800 text-white"
                        value={parseFloat(asset.quantity)}
                        disabled
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-white mb-2">Montant actuel</label>
                    <input
                        type="number"
                        className="w-full p-2 rounded bg-gray-800 text-white"
                        value={parseFloat(asset.value)}
                        disabled
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-white mb-2">Pourcentage de bénéfices</label>
                    <input
                        type="number"
                        step={"any"}
                        className="w-full p-2 rounded bg-gray-800 text-white"
                        value={(benefitsPercentage)}
                        onChange={(e) => setBenefitsPercentage(e.target.value)}
                    />
                </div>
                <div className="flex justify-end space-x-4">
                    <button
                        onClick={onClose}
                        className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-400 transition duration-200"
                    >
                        Annuler
                    </button>
                    <button
                        onClick={handleSubmit}
                        className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-400 transition duration-200"
                    >
                        Mettre à jour
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UpdateAssetBenefits;
