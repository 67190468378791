import React from 'react';
import {Link, useParams} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import PortfolioCard from './PortfolioCard'; // Assurez-vous que le chemin est correct

const PortfolioTable = ({ portfolios }) => {
    const { category } = useParams();
    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-2xl font-bold text-gray-800">Liste des Portefeuilles</h3>
                <Link to={`/create-portfolio/${category}`} className="text-black hover:text-black">
                    <FontAwesomeIcon icon={faPlusCircle} size="2x" />
                </Link>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {portfolios.map(portfolio => (
                    <PortfolioCard key={portfolio.id} portfolio={portfolio} />
                ))}
            </div>
        </div>
    );
};

export default PortfolioTable;
