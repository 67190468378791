import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import api from '../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from "../../context/UserContext";
import NotificationManager from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import NumericAssetCreate from './forms/NumericAssetCreate';
import StandardAssetCreate from "./forms/StandardAssetCreate";

const CreateAsset = ({ assetCategories }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { category } = location.state || {};
    const { assets } = location.state || {};
    const { user, getAssetCategoriesSummary } = useContext(UserContext);
    const [selectedAsset, setSelectedAsset] = useState(null);

    const renderForm = () => {
        if (category === 'nft' || category === 'crypto' || category === 'stock' || category === 'commodity') {
            return (
                <NumericAssetCreate
                    category={category}
                    portfolioAssets={assets}
                    getAssetCategoriesSummary={getAssetCategoriesSummary}
                    selectedAsset={selectedAsset}
                    setSelectedAsset={setSelectedAsset}
                />
            );
        } else {
            return <StandardAssetCreate category={category} getAssetCategoriesSummary={getAssetCategoriesSummary} />;
        }
        // Vous pouvez ajouter d'autres composants de formulaire pour d'autres catégories ici
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <div className="flex justify-between mb-4">
                    <button
                        onClick={() => navigate(-1)}
                        className="flex items-center text-yellow-500 hover:text-yellow-400"
                    >
                        <FontAwesomeIcon icon={faArrowLeft} size="2x" className="mr-2" />
                        <span>Retour</span>
                    </button>
                </div>
                <h2 className="text-3xl sm:text-4xl font-bold text-yellow-500 mb-6">
                    Ajouter un Actif {category ? `- ${assetCategories.find((type) => type.value === category)?.label}` : ''}
                </h2>
                {renderForm()}
            </div>
        </div>
    );
};

export default CreateAsset;
