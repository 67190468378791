// components/Unauthorized.js
import React from 'react';
import { Link } from 'react-router-dom';

const Unauthorized = () => {
    return (
        <div className="container mx-auto px-6 py-12">
            <h2 className="text-4xl font-bold text-red-600 mb-6">403 - Accès refusé</h2>
            <p className="text-lg text-gray-600 mb-6">
                Vous n'avez pas la permission d'accéder à cette page.
            </p>
            <Link to="/" className="bg-blue-600 text-white text-lg py-2 px-4 rounded shadow hover:bg-blue-700">
                Retour à l'accueil
            </Link>
        </div>
    );
};

export default Unauthorized;
