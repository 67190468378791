import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';

const AssetCategoriesList = ({ assetCategories, onSelectCategory }) => {
    const { user, assetCategoriesSummary } = useContext(UserContext);
    const [hoveredCategory, setHoveredCategory] = useState(null); // État pour suivre la catégorie survolée

    // Identifier les catégories pour lesquelles l'utilisateur a déjà un portefeuille
    const userHasPortfolioInCategory = (category) => {
        return assetCategoriesSummary[category?.value]?.portfolio_count > 0 && user.subscription_type === null;
    };

    return (
        <div className="flex flex-col">
            <div className="text-2xl font-bold text-gray-200 mb-4">Choisissez une catégorie d'actifs</div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {assetCategories.map((category) => {
                    const hasPortfolio = userHasPortfolioInCategory(category);

                    return (
                        <div
                            key={category.value}
                            onClick={() => !hasPortfolio && onSelectCategory(category)}  // Ne permet de sélectionner que si l'utilisateur n'a pas déjà un portefeuille
                            onMouseEnter={() => hasPortfolio && setHoveredCategory(category.value)}  // Affiche la tooltip au survol
                            onMouseLeave={() => setHoveredCategory(null)}  // Cache la tooltip lorsque le survol s'arrête
                            className={`relative cursor-pointer p-6 rounded-lg shadow-lg flex items-center justify-between transition duration-300 ease-in-out 
                            ${hasPortfolio ? 'bg-gray-600 cursor-not-allowed' : 'bg-gray-800 hover:bg-gray-700'}`}
                        >
                            <div className="text-yellow-500 text-3xl mr-4">
                                {category.icon}
                            </div>
                            <div className="flex-grow">
                                <h3 className="text-xl font-bold text-yellow-500">{category.label}</h3>
                            </div>
                            {hasPortfolio && hoveredCategory === category.value && (
                                <div className="absolute top-full left-0 mt-2 w-64 bg-gray-700 text-white p-2 rounded shadow-lg z-10">
                                    Vous avez déjà un portefeuille dans cette catégorie. Passez à un abonnement supérieur pour en créer plus d'un.
                                    <Link to="/subscriptions"
                                          className="block mt-2 bg-yellow-500 text-black px-3 py-1 rounded shadow hover:bg-yellow-600 transition duration-300 ease-in-out text-center">
                                        Voir les abonnements
                                    </Link>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default AssetCategoriesList;
