import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faLock } from '@fortawesome/free-solid-svg-icons';
import { faStripe } from '@fortawesome/free-brands-svg-icons'; // Stripe icon from FontAwesome
import { subscriptions } from './Subscriptions';

// Load your Stripe public key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Checkout = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { subscriptionId } = location.state || {};

    const subscription = subscriptions.find(sub => sub.annual_id === subscriptionId);

    return (
        <Elements stripe={stripePromise}>
            <div className="relative flex flex-col justify-center items-center min-h-screen bg-gradient-to-br from-gray-900 via-black to-gray-800 text-white p-6 pt-16">
                <button
                    onClick={() => navigate('/subscriptions')}
                    className="absolute top-16 left-4 flex items-center text-white bg-gray-700 hover:bg-gray-600 px-4 py-2 rounded-lg shadow-lg transition duration-300"
                >
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                    Retour
                </button>
                <div className="flex flex-col md:flex-row justify-center items-center w-full">
                    {subscription ? (
                        <div className={`p-8 rounded-xl shadow-2xl transform transition-transform duration-300 hover:scale-105 ${subscription.bgColor} flex flex-col justify-between text-center w-full md:w-1/2 max-w-md mx-auto mb-8 md:mb-0`}>
                            <div>
                                <h2 className={`text-3xl font-extrabold mb-4 flex items-center justify-center ${subscription.textColor}`}>
                                    <FontAwesomeIcon icon={subscription.icon} className="mr-2" />
                                    Abonnement {subscription.name}
                                </h2>
                                <p className={`text-2xl font-bold mb-6 ${subscription.textColor}`}>{subscription.annual_price} €/an</p>
                                <ul className="mb-8 space-y-4">
                                    {subscription.benefits.map((benefit, index) => (
                                        <li key={index} className={`text-lg ${subscription.textColor}`}>
                                            <span className="inline-block mr-2">✓</span>
                                            {benefit}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ) : (
                        <p className="text-red-500">Erreur : Abonnement non trouvé.</p>
                    )}
                    <div className="w-full md:w-1/2 max-w-md mx-auto">
                        <div className="bg-white text-black p-8 rounded-lg shadow-2xl">
                            <h3 className="text-2xl font-bold text-center mb-6">
                                Paiement sécurisé <FontAwesomeIcon icon={faLock} />
                            </h3>
                            <CheckoutForm subscriptionId={subscriptionId} />
                            <div className="flex items-center justify-center mt-6">
                                <FontAwesomeIcon icon={faStripe} className="text-blue-600 text-3xl mr-2" />
                                <p className="text-sm text-gray-600">Paiement sécurisé et protégé par Stripe</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Elements>
    );
};

export default Checkout;
