import React from 'react';
import {Link} from "react-router-dom";

const Features = () => {
    return (
        <div className="min-h-screen flex flex-col items-center bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white p-8">
            <div className="max-w-5xl w-full bg-gray-800 p-8 rounded-lg shadow-lg border border-gray-700 mt-16 mb-8">
                <h2 className="text-4xl font-bold text-yellow-500 mb-6 text-center">Fonctionnalités de Stonqle</h2>
                <p className="text-lg text-gray-300 mb-6">
                    Stonqle offre une gamme complète de fonctionnalités pour vous aider à gérer efficacement vos investissements :
                </p>
                <ul className="text-lg text-gray-300 mb-6 space-y-3">
                    <li>🔒 <strong>Sécurité Avancée</strong> : Protégez vos données et vos investissements avec nos mesures de sécurité de pointe.</li>
                    <li>📊 <strong>Outils d'Analyse</strong> : Accédez à des outils d'analyse et de reporting avancés pour suivre la performance de vos portefeuilles.</li>
                    <li>🌍 <strong>Accessibilité Globale</strong> : Gérez vos investissements depuis n'importe quel appareil, où que vous soyez dans le monde.</li>
                    <li>📈 <strong>Conseils Personnalisés</strong> : Recevez des conseils personnalisés pour optimiser vos investissements en fonction de vos objectifs.</li>
                    <li>💡 <strong>Interface Intuitive</strong> : Profitez d'une interface utilisateur intuitive et facile à utiliser pour une gestion simplifiée.</li>
                    <li>📅 <strong>Suivi en Temps Réel</strong> : Suivez vos investissements en temps réel avec des mises à jour instantanées.</li>
                    <li>🤝 <strong>Support Client Dédié</strong> : Bénéficiez d'un support client dédié pour répondre à toutes vos questions et préoccupations.</li>
                </ul>
                <div className="flex justify-center">
                    <Link to="/" className="bg-yellow-500 text-black text-lg py-2 px-4 rounded shadow hover:bg-yellow-600 transition duration-300 ease-in-out">
                        Retour à l'accueil
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Features;
