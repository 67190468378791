import React, {useContext, useState, useRef, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import api from '../api';
import logo from '../assets/logo-no-background.png';
import Switch from 'react-switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faEye, faEyeSlash, faTachometerAlt, faUser, faCog, faDollarSign, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
    const { user, setUser, preferences, toggleHideAmounts } = useContext(UserContext);
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef(null);

    const handleLogout = () => {
        setUser(null);
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user');
        api.defaults.headers['Authorization'] = '';
        navigate('/login');
    };

    const toggleMenu = () => {
        setMenuOpen(prev => !prev);
    };

    const closeMenu = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', closeMenu);
        return () => {
            document.removeEventListener('mousedown', closeMenu);
        };
    }, []);

    const subscriptionColors = {
        gold: 'bg-yellow-500',
        diamond: 'bg-indigo-500',
    };

    const subscriptionType = user?.subscription_type || '';
    const subscriptionBgColor = subscriptionColors[subscriptionType.toLowerCase()] || 'bg-dark-200';

    return (
        <header className="bg-black text-white p-4 flex justify-between items-center shadow-lg relative">
            <Link to={'/'} className="text-white flex items-center">
                <img src={logo} alt="Logo" className="h-8 mr-2" />
            </Link>
            <div className="flex items-center space-x-4">
                {user ? (
                    <>
                        <div className="flex items-center">
                            <span className="mr-2 hidden sm:block">Cacher Montants</span>
                            <Switch
                                onChange={toggleHideAmounts}
                                checked={preferences.hideAmounts}
                                offColor="#ccc"
                                onColor="#4caf50"
                                offHandleColor="#fff"
                                onHandleColor="#fff"
                                uncheckedIcon={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                            fontSize: 18,
                                            color: 'orange',
                                            paddingRight: 2,
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faEye} />
                                    </div>
                                }
                                checkedIcon={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                            fontSize: 18,
                                            color: 'green',
                                            paddingRight: 2,
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faEyeSlash} />
                                    </div>
                                }
                                height={24}
                                width={48}
                                handleDiameter={22}
                            />
                        </div>
                        <button
                            onClick={handleLogout}
                            className="bg-yellow-500 text-black px-4 py-2 rounded shadow hover:bg-yellow-600 transition duration-300 flex items-center"
                        >
                            <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                            <span className="hidden sm:inline">Se déconnecter</span>
                        </button>

                        <div className="relative z-50">  {/* Ajout de z-50 pour un z-index élevé */}
                            <img
                                src={user?.profile_pic_url || '/default.jpg'}
                                alt="Profile"
                                className="h-10 w-10 rounded-full cursor-pointer"
                                onClick={toggleMenu}
                                onMouseEnter={() => setMenuOpen(true)}
                                onMouseLeave={() => setMenuOpen(false)}
                            />
                            <div
                                ref={menuRef}
                                className={`absolute right-0 mt-2 w-56 ${subscriptionBgColor} text-white rounded-lg shadow-lg transform transition-all duration-300 ease-out ${
                                    menuOpen ? 'max-h-72 opacity-100' : 'max-h-0 opacity-0'
                                }`}
                                onMouseEnter={() => setMenuOpen(true)}
                                onMouseLeave={() => setMenuOpen(false)}
                                style={{ overflow: 'hidden', zIndex: 50 }}
                            >
                                <div className="px-4 py-2 text-sm font-semibold border-b border-gray-700">
                                    Bienvenue, {user.username}
                                    <div className="text-xs mt-1">Abonnement : {subscriptionType.charAt(0).toUpperCase() + subscriptionType.slice(1)}</div>
                                </div>
                                <Link
                                    to="/"
                                    className="flex items-center px-4 py-2 hover:bg-gray-700"
                                    onClick={() => setMenuOpen(false)}
                                >
                                    <FontAwesomeIcon icon={faTachometerAlt} className="mr-2" />
                                    Dashboard
                                </Link>
                                <Link
                                    to="/profile"
                                    className="flex items-center px-4 py-2 hover:bg-gray-700"
                                    onClick={() => setMenuOpen(false)}
                                >
                                    <FontAwesomeIcon icon={faUser} className="mr-2" />
                                    Profil
                                </Link>
                                <Link
                                    to="/settings"
                                    className="flex items-center px-4 py-2 hover:bg-gray-700"
                                    onClick={() => setMenuOpen(false)}
                                >
                                    <FontAwesomeIcon icon={faCog} className="mr-2" />
                                    Réglages
                                </Link>
                                <Link
                                    to="/subscriptions"
                                    className="flex items-center px-4 py-2 hover:bg-gray-700"
                                    onClick={() => setMenuOpen(false)}
                                >
                                    <FontAwesomeIcon icon={faDollarSign} className="mr-2" />
                                    Abonnements
                                </Link>
                                {
                                    user.subscription_type === 'diamond' && (
                                        <Link
                                            to="mailto:contact@pixlesolutions.com"
                                            className="flex items-center px-4 py-2 hover:bg-gray-700"
                                            onClick={() => setMenuOpen(false)}
                                        >
                                            <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                                            Contact
                                        </Link>
                                    )
                                }

                            </div>
                        </div>
                    </>
                ) : (
                    <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
                        <Link to="/login" className="bg-yellow-500 text-black px-4 py-2 rounded shadow hover:bg-yellow-600 transition duration-300">
                            Se connecter
                        </Link>
                        <Link to="/register" className="bg-yellow-500 text-black px-4 py-2 rounded shadow hover:bg-yellow-600 transition duration-300">
                            Créer un compte
                        </Link>
                    </div>
                )}
            </div>
        </header>
    );
};

export default Header;
