import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import api from '../../../api';

const RegisterStep2 = ({ prevStep, nextStep, setAccountInfo }) => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [errors, setErrors] = useState({});

    const checkUsernameAvailability = async (username) => {
        try {
            const response = await api.post('/check-username/', { username });
            return response.data.available;
        } catch (error) {
            console.error('Erreur lors de la vérification du nom d\'utilisateur', error);
            return false;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = {};

        if (!username) validationErrors.username = 'Nom d\'utilisateur requis';
        if (!email) validationErrors.email = 'Email requis';
        if (!password) validationErrors.password = 'Mot de passe requis';
        if (password !== password2) {
            validationErrors.password2 = 'Les mots de passe ne correspondent pas';
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            NotificationManager.error('Erreur lors de la saisie des informations de connexion');
            return;
        }

        const isUsernameAvailable = await checkUsernameAvailability(username);
        if (!isUsernameAvailable) {
            setErrors((prevErrors) => ({ ...prevErrors, username: 'Nom d\'utilisateur déjà pris' }));
            NotificationManager.error('Nom d\'utilisateur déjà pris');
            return;
        }

        setAccountInfo({ username, email, password });
        nextStep();
    };

    return (
        <div className="w-full max-w-4xl mx-auto p-8 rounded-lg shadow-md">
            <h2 className="text-3xl font-semibold text-center text-yellow-500 mb-4">Informations de connexion</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div className="relative">
                    <label className="block text-yellow-500 text-lg font-bold mb-2" htmlFor="username">
                        Nom d'utilisateur
                    </label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="w-full px-4 py-2 bg-gray-800 border border-gray-300 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                        required
                    />
                    {errors.username && <p className="text-red-500 text-sm mt-1">{errors.username}</p>}
                </div>
                <div className="relative">
                    <label className="block text-yellow-500 text-lg font-bold mb-2" htmlFor="email">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-4 py-2 bg-gray-800 border border-gray-300 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                        required
                    />
                    {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                </div>
                <div className="relative">
                    <label className="block text-yellow-500 text-lg font-bold mb-2" htmlFor="password">
                        Mot de passe
                    </label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full px-4 py-2 bg-gray-800 border border-gray-300 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                        required
                    />
                    {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
                </div>
                <div className="relative">
                    <label className="block text-yellow-500 text-lg font-bold mb-2" htmlFor="password2">
                        Confirmer le mot de passe
                    </label>
                    <input
                        type="password"
                        id="password2"
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                        className="w-full px-4 py-2 bg-gray-800 border border-gray-300 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                        required
                    />
                    {errors.password2 && <p className="text-red-500 text-sm mt-1">{errors.password2}</p>}
                </div>
                <button
                    type="submit"
                    className="w-full py-3 bg-yellow-500 text-black text-lg font-semibold rounded-lg shadow-md hover:bg-yellow-600 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                >
                    Suivant
                </button>
                <button
                    type="button"
                    onClick={prevStep}
                    className="w-full py-3 bg-gray-500 text-black text-lg font-semibold rounded-lg shadow-md hover:bg-gray-400 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 mt-2"
                >
                    Précédent
                </button>
            </form>
        </div>
    );
};

export default RegisterStep2;
