import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CreatePortfolio from './components/portfolio/CreatePortfolio';
import UpdatePortfolioValue from './components/portfolio/values/UpdatePortfolioValue';
import Dashboard from './components/Dashboard';
import PortfolioHistory from "./components/portfolio/PortfolioHistory";
import Login from './components/user/Login';
import Register from './components/user/Register';
import Header from './components/Header';
import LandingPage from './components/LandingPage';
import ProtectedRoute from './components/ProtectedRoute';
import Unauthorized from './components/Unauthorized';
import { UserProvider } from './context/UserContext';
import RegistrationConfirmation from "./components/RegistrationConfirmation";
import ActivateAccount from "./components/ActivateAccount";
import UpdatePortfolio from "./components/portfolio/UpdatePortfolio";
import { NotificationContainer } from "react-notifications";
import Features from "./components/Features";
import CreateAsset from "./components/assets/CreateAsset";
import CookieConsent from "./components/CookieConsent";
import Profile from "./components/user/Profile";
import Settings from "./components/user/Settings";
import Subscriptions from "./components/subscriptions/Subscriptions";
import Checkout from "./components/subscriptions/Checkout";
import { FaBitcoin, FaBox, FaBuilding, FaChessBoard, FaClock, FaDollarSign, FaGem, FaQuestion, FaUniversity } from "react-icons/fa";
import PortfolioList from "./components/portfolio/PortfolioList";
import { GrCertificate } from "react-icons/gr";
import AssetDetails from "./components/assets/AssetDetails";
import PullToRefresh from 'react-simple-pull-to-refresh';  // Utilisation d'une autre librairie simple de pull-to-refresh
import { Oval } from 'react-loader-spinner';  // Utilisation du loader circulaire

const assetCategories = [
    { value: 'stock', label: 'Actions', icon: <FaDollarSign /> },
    { value: 'bond', label: 'Obligations', icon: <FaUniversity /> },
    { value: 'real_estate', label: 'Immobilier', icon: <FaBuilding /> },
    { value: 'crypto', label: 'Cryptomonnaies', icon: <FaBitcoin /> },
    { value: 'commodity', label: 'Matières Premières', icon: <FaGem /> },
    { value: 'trading_cards', label: 'Cartes à collectionner', icon: <FaChessBoard /> },
    { value: 'sealed_objects', label: 'Objets scellés', icon: <FaBox /> },
    { value: 'nft', label: 'NFT', icon: <GrCertificate /> },
    { value: 'watches', label: 'Montres', icon: <FaClock /> },
    { value: 'bank_account', label: 'Compte épargne', icon: <FaUniversity /> },
    { value: 'other', label: 'Autre', icon: <FaQuestion /> },
];

function App() {
    const [isPulling, setIsPulling] = useState(false);

    const handleRefresh = () => {
        return new Promise((resolve) => {
            setIsPulling(true);
            setTimeout(() => {
                window.location.reload();  // Recharger la page après le refresh
                resolve();
                setIsPulling(false);
            }, 1000);  // Durée avant le rechargement (1 seconde)
        });
    };

    return (
        <UserProvider>
            <div style={{ overflowY: 'auto', height: '100vh' }}>
                <PullToRefresh
                    onRefresh={handleRefresh}
                    backgroundColor={'#000'}
                    pullDownThreshold={70}  // Définir la sensibilité de l'action
                    refreshingContent={
                        <div style={{ padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Oval height={40} width={40} color="#fff" ariaLabel="loading" />
                        </div>
                    }  // Loader qui s'affiche pendant le "pull"
                    pullingContent={
                        <div style={{ padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {isPulling && <Oval height={40} width={40} color="#fff" ariaLabel="loading" />}
                        </div>
                    }  // Loader qui s'affiche pendant le "pull"
                    resistance={2.5}  // Ajustement de la résistance du pull
                >
                    <Router>
                        <div>
                            <Header />
                            <Routes>
                                <Route path="/" element={<ProtectedRoute><Dashboard assetCategories={assetCategories} /></ProtectedRoute>} />
                                <Route path="/create-portfolio/:category" element={<ProtectedRoute><CreatePortfolio assetCategories={assetCategories} /></ProtectedRoute>} />
                                <Route path="/create-asset/:id" element={<ProtectedRoute><CreateAsset assetCategories={assetCategories} /></ProtectedRoute>} />
                                <Route path="/update-portfolio-value/:id" element={<ProtectedRoute><UpdatePortfolioValue /></ProtectedRoute>} />
                                <Route path="/update-portfolio/:id" element={<ProtectedRoute><UpdatePortfolio /></ProtectedRoute>} />
                                <Route path="/assets/:assetId" element={<ProtectedRoute><AssetDetails /></ProtectedRoute>} />
                                <Route path="/portfolio-history/:id" element={<ProtectedRoute><PortfolioHistory assetCategories={assetCategories} /></ProtectedRoute>} />
                                <Route path="/register" element={<Register />} />
                                <Route path="/portfolio-list/:category" element={<ProtectedRoute><PortfolioList assetCategories={assetCategories} /></ProtectedRoute>} />
                                <Route path="/registration-confirmation" component={RegistrationConfirmation} />
                                <Route path="/activate/:uidb64/:token" element={<ActivateAccount />} />
                                <Route path="/email-confirmation" element={<RegistrationConfirmation />} />
                                <Route path="/login" element={<Login />} />
                                <Route path="/landing" element={<LandingPage />} />
                                <Route path="/features" element={<ProtectedRoute><Features /></ProtectedRoute>} />
                                <Route path="/unauthorized" element={<Unauthorized />} />
                                <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
                                <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
                                <Route path="/subscriptions" element={<Subscriptions />} />
                                <Route path="/checkout" element={<Checkout />} />
                            </Routes>
                            <NotificationContainer />
                            <CookieConsent />
                        </div>
                    </Router>
                </PullToRefresh>
            </div>
        </UserProvider>
    );
}

export default App;
