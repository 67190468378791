import React from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

Modal.setAppElement('#root');

const DeletePortfolioModal = ({ isOpen, onRequestClose, onDelete }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Confirmer la suppression"
            className="fixed inset-0 flex items-center justify-center"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
        >
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-full max-w-md mx-auto">
                <h2 className="text-2xl font-bold mb-4 text-yellow-500">Confirmer la suppression</h2>
                <p className="text-gray-300 mb-6">Êtes-vous sûr de vouloir supprimer ce portefeuille ? Cette action est irréversible.</p>
                <div className="flex justify-end space-x-4">
                    <button onClick={onRequestClose} className="bg-gray-600 text-white text-lg py-2 px-4 rounded shadow hover:bg-gray-700">
                        Annuler
                    </button>
                    <button onClick={onDelete} className="bg-red-600 text-white text-lg py-2 px-4 rounded shadow hover:bg-red-700">
                        <FontAwesomeIcon icon={faTrash} className="mr-2" /> Supprimer
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default DeletePortfolioModal;
