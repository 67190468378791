import React, { useState, useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import api from '../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Circles } from 'react-loader-spinner'; // Import the loader

const Profile = () => {
    const { user, fetchUser } = useContext(UserContext);
    const [profilePic, setProfilePic] = useState(user?.profile_pic_url || '/default.jpg');
    const [previewPic, setPreviewPic] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleProfilePicChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setPreviewPic(URL.createObjectURL(file));
            setProfilePic(file);

            setIsSubmitting(true);
            console.log(file)
            try {
                const formData = new FormData();
                formData.append('profilePic', file);
                console.log("eeeee")

                await api.put('/user/update-profile/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                await fetchUser();
                NotificationManager.success('Photo de profil mise à jour avec succès !', 'Succès');
            } catch (error) {
                console.error('Erreur lors de la mise à jour de la photo de profil:', error);
                NotificationManager.error('Fichier trop volumineux.', 'Erreur');
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    const handleImageClick = () => {
        document.getElementById('fileInput').click();
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gradient-to-br from-gray-800 via-black to-gray-900 text-white">
            <div className="p-8 bg-gray-900 rounded-lg shadow-lg text-center w-full max-w-3xl">
                <h1 className="text-4xl font-bold mb-6 text-yellow-500">Profil</h1>
                <div className="flex flex-col md:flex-row items-center md:items-start justify-between mb-6">
                    <div className="relative mb-6 md:mb-0 md:mr-6">
                        <div className="group relative w-40 h-40">
                            {isSubmitting ? (
                                <div className="flex justify-center items-center w-full h-full">
                                    <Circles
                                        height="80"
                                        width="80"
                                        color="#facc15"
                                        ariaLabel="circles-loading"
                                        visible={true}
                                    />
                                </div>
                            ) : (
                                <>
                                    <img
                                        src={previewPic || profilePic}
                                        alt="Profile"
                                        className="w-full h-full rounded-full object-cover border-4 border-gray-700 transition-all duration-300 ease-in-out cursor-pointer"
                                        onClick={handleImageClick}
                                    />
                                    <div
                                        className="absolute inset-0 bg-black bg-opacity-50 rounded-full opacity-0 group-hover:opacity-100 flex items-center justify-center transition-opacity duration-300 cursor-pointer"
                                        onClick={handleImageClick}
                                    >
                                        <FontAwesomeIcon icon={faPen} className="text-white text-3xl" />
                                    </div>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        accept="image/*"
                                        onChange={handleProfilePicChange}
                                        className="hidden"
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <div className="flex-1 text-left">
                        <p className="text-xl font-bold mb-2">Informations actuelles :</p>
                        <p className="mb-4"><strong>Pseudo :</strong> {user?.username}</p>
                        <p className="mb-4"><strong>Email :</strong> {user?.email}</p>
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );
};

export default Profile;
