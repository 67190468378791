import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import api from '../../api';
import PortfolioTable from './PortfolioTable';
import Loader from '../Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const PortfolioList = ({ assetCategories }) => {
    const { category } = useParams();
    const [portfolios, setPortfolios] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPortfolios = async () => {
            try {
                const response = await api.get('/portfolio-list/', {
                    params: { category }
                });
                setPortfolios(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des portefeuilles', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPortfolios();
    }, [category]);

    if (isLoading) {
        return <Loader />;
    }

    // Get the label of the category from the assetCategories or mock it for demonstration
    const categoryLabel = assetCategories.find(c => c.value === category)?.label || category;

    return (
        <div className="min-h-screen bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <div className="flex justify-between items-center mb-4">
                <Link to={`/`} className="flex items-center text-yellow-500 hover:text-yellow-400">
                        <FontAwesomeIcon icon={faArrowLeft} size="2x" className="mr-2" />
                        <span>Retour</span>
                    </Link>
                    <h2 className="text-3xl font-bold text-yellow-500 text-center">
                        Liste des portefeuilles - {categoryLabel}
                    </h2>
                    <div className="w-16"></div>
                </div>
                <PortfolioTable portfolios={portfolios} />
            </div>
        </div>
    );
};

export default PortfolioList;
