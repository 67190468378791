import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import LoadingPage from './LoadingPage';  // Import du composant LoadingPage

const ProtectedRoute = ({ children }) => {
    const { user, setUser } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkUser = async () => {
            const savedUser = localStorage.getItem('user');
            const accessToken = localStorage.getItem('access_token');
            if (savedUser && accessToken) {
                setUser(JSON.parse(savedUser));
            }
            setIsLoading(false);
        };

        checkUser();
    }, [setUser]);

    if (isLoading) {
        return <LoadingPage />;  // Afficher la page de chargement
    }

    if (!user) {
        return <Navigate to="/landing" />;
    }

    return children;
};

export default ProtectedRoute;
