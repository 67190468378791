// components/CookieConsent.js

import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../context/UserContext';
import Cookies from 'js-cookie';  // Importer js-cookie correctement

const CookieConsent = () => {
    const { preferences, saveCookiePreferences } = useContext(UserContext);
    const [visible, setVisible] = useState(false);
    const [localPreferences, setLocalPreferences] = useState(preferences.cookies);

    useEffect(() => {
        // Vérifier si le consentement aux cookies a déjà été donné
        const consentGiven = Cookies.get('cookie_consent');
        if (!consentGiven) {
            setVisible(true);
        }
    }, []);

    const handleAcceptAll = () => {
        const allAccepted = { essential: true, analytics: true, marketing: true };
        setLocalPreferences(allAccepted);
        saveCookiePreferences(allAccepted);
        setVisible(false);
    };

    const handleSavePreferences = () => {
        saveCookiePreferences(localPreferences);
        setVisible(false);
    };

    if (!visible) {
        return null;
    }

    return (
        <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-4 shadow-lg flex flex-col sm:flex-row justify-between items-center">
            <div className="mb-4 sm:mb-0">
                <p className="text-sm">
                    Nous utilisons des cookies pour améliorer votre expérience. Vous pouvez accepter tous les cookies ou personnaliser vos préférences.
                </p>
            </div>
            <div className="flex space-x-2">
                <button
                    onClick={handleAcceptAll}
                    className="bg-green-500 text-black px-4 py-2 rounded shadow hover:bg-green-600 transition duration-300"
                >
                    Accepter tout
                </button>
                <button
                    onClick={() => setVisible(true)}
                    className="bg-yellow-500 text-black px-4 py-2 rounded shadow hover:bg-yellow-600 transition duration-300"
                >
                    Personnaliser
                </button>
            </div>
            {visible && (
                <div className="mt-4 sm:mt-0 sm:ml-4 flex flex-col">
                    <label className="flex items-center">
                        <input
                            type="checkbox"
                            checked={localPreferences.essential}
                            onChange={(e) => setLocalPreferences({ ...localPreferences, essential: e.target.checked })}
                            disabled
                        />
                        <span className="ml-2">Essentiel (obligatoire)</span>
                    </label>
                    <label className="flex items-center mt-2">
                        <input
                            type="checkbox"
                            checked={localPreferences.analytics}
                            onChange={(e) => setLocalPreferences({ ...localPreferences, analytics: e.target.checked })}
                        />
                        <span className="ml-2">Analytique</span>
                    </label>
                    <label className="flex items-center mt-2">
                        <input
                            type="checkbox"
                            checked={localPreferences.marketing}
                            onChange={(e) => setLocalPreferences({ ...localPreferences, marketing: e.target.checked })}
                        />
                        <span className="ml-2">Marketing</span>
                    </label>
                    <button
                        onClick={handleSavePreferences}
                        className="bg-blue-500 text-black px-4 py-2 rounded shadow hover:bg-blue-600 transition duration-300 mt-2"
                    >
                        Sauvegarder
                    </button>
                </div>
            )}
        </div>
    );
};

export default CookieConsent;
