import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../api';

const ActivateAccount = () => {
    const { uidb64, token } = useParams();
    const [status, setStatus] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        async function activate() {
            try {
                const response = await api.get(`/activate/${uidb64}/${token}/`);
                setStatus(response.data);
                // Redirection après activation
                if (response.data === 'Account activated successfully') {
                    setTimeout(() => {
                        navigate('/login');
                    }, 3000); // Rediriger après 3 secondes
                }
            } catch (error) {
                setStatus('Activation link is invalid');
            }
        }

        activate();
    }, [uidb64, token, navigate]);

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">Activation du compte</h2>
                <p className={`text-lg mb-6 ${status === 'Activation link is invalid' ? 'text-red-600' : 'text-green-600'}`}>
                    {status === 'Activation link is invalid' ? 'L\'activation du compte a échoué !' : 'Compte activé avec succès !' }
                </p>
                {status === 'Account activated successfully' && (
                    <p className="text-gray-600">Redirection vers la page de connexion...</p>
                )}
                {status === 'Activation link is invalid' && (
                    <div className="mt-6">
                        <a
                            href="/resend-activation"
                            className="bg-blue-600 text-white py-2 px-4 rounded shadow hover:bg-blue-700 transition duration-300"
                        >
                            Renvoyer le lien d'activation
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ActivateAccount;
