import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPen } from '@fortawesome/free-solid-svg-icons';
import { FiShoppingCart, FiDollarSign } from 'react-icons/fi';
import { NotificationManager } from 'react-notifications';
import { Circles } from 'react-loader-spinner';
import { UserContext } from "../../context/UserContext";
import api from '../../api';
import TotalValueOverTime from './../charts/TotalValueOverTime';
import { formatAmount } from '../../utils';
import 'react-notifications/lib/notifications.css';

const TransactionBanner = ({ transaction, hideAmounts }) => {
    const isBuy = transaction.transaction_type === 'buy';
    const icon = isBuy ? <FiShoppingCart /> : <FiDollarSign />;
    const textColor = isBuy ? 'text-green-500' : 'text-red-500';

    return (
        <div className="flex items-center p-4 border-b border-gray-700 mb-4">
            <div className={`text-2xl mr-4 ${textColor}`}>
                {icon}
            </div>
            <div className="flex-grow">
                <p className="font-semibold text-lg">{transaction.transaction_type === 'buy' ? 'Achat' : (transaction.transaction_type === 'sell') ? 'Vente' : 'Edition'}</p>
                <p className="text-sm text-gray-400">
                    {new Date(transaction.date).toLocaleString()} - Quantité: {transaction.quantity}, Prix: {formatAmount(transaction.price, hideAmounts)}, Valeur totale: {formatAmount(transaction.total_value, hideAmounts)}
                </p>
            </div>
        </div>
    );
};

const AssetBanner = ({ asset, hideAmounts, portfolioAsset }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [previewPic, setPreviewPic] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setPreviewPic(URL.createObjectURL(file));
            setSelectedFile(file);
            updateAssetImage(file, asset);
        }
    };

    const updateAssetImage = async (file, asset) => {
        if (!file) {
            NotificationManager.error('Veuillez sélectionner une image.', 'Erreur');
            return;
        }

        setIsSubmitting(true);
        try {
            const formData = new FormData();
            formData.append('image', file);
            formData.append('asset_id', asset.id);

            await api.put(`/assets/${asset.id}/update-image/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            NotificationManager.success('Image de l\'actif mise à jour avec succès !', 'Succès');
        } catch (error) {
            NotificationManager.error('Erreur lors de la mise à jour de l\'image de l\'actif.', 'Erreur');
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleImageClick = () => {
        document.getElementById('fileInput').click();
    };

    return (
        <div className="flex items-center p-4 border-b border-gray-700 mb-4">
            <div className="relative group w-16 h-16 mr-4">
                {isSubmitting ? (
                    <Circles
                        height="64" // Taille du loader modifiée pour être égale à celle de l'image (16px)
                        width="64"
                        color="#facc15"
                        ariaLabel="circles-loading"
                        visible={true}
                    />
                ) : (
                    <>
                        <img
                            src={previewPic || asset.image_url || '/default.jpg'}
                            alt={asset.asset_name}
                            className="w-16 h-16 object-cover rounded-full mr-4 cursor-pointer border-4 border-gray-700 transition-all duration-300 ease-in-out"
                            onClick={handleImageClick}
                        />
                        <div
                            className="absolute inset-0 bg-black bg-opacity-50 rounded-full opacity-0 group-hover:opacity-100 flex items-center justify-center transition-opacity duration-300 cursor-pointer w-16 h-16"
                            onClick={handleImageClick}
                        >
                            <FontAwesomeIcon icon={faPen} className="text-white text-xl" />
                        </div>
                    </>
                )}
                <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="hidden"
                />
            </div>
            <div className="flex-grow">
                <p className="font-semibold text-lg">{asset.user > 0 ? '' : asset.short_name + ' - '} {asset.name}</p>
                <p className="text-sm text-gray-400">
                    Quantité: {portfolioAsset.quantity > 0 ? parseFloat(portfolioAsset.quantity).toFixed(2) : 0}, Valeur: {formatAmount(portfolioAsset.value, hideAmounts)}
                </p>
            </div>
        </div>
    );
};

const AssetDetail = () => {
    const { assetId } = useParams();
    const [valueHistory, setValueHistory] = useState([]);
    const [profits, setProfits] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [portfolioAsset, setPortfolioAsset] = useState(null);
    const [asset, setAsset] = useState(null);
    const navigate = useNavigate();
    const { preferences } = useContext(UserContext);
    const { hideAmounts } = preferences;

    useEffect(() => {
        const fetchAssetDetails = async () => {
            try {
                const response = await api.get(`/portfolio-assets/${assetId}/`);
                const { portfolio_asset, value_history, profits, transactions } = response.data;

                const formattedValueHistory = value_history.map(item => ({
                    ...item,
                    date: item.date,
                }));

                setValueHistory(formattedValueHistory || []);
                setPortfolioAsset(portfolio_asset || {});
                setProfits(profits || []);

                const sortedTransactions = transactions.sort((a, b) => new Date(b.date) - new Date(a.date));
                setTransactions(sortedTransactions || []);
            } catch (error) {
                console.error('Erreur lors de la récupération des détails de l\'actif', error);
            }
        };

        fetchAssetDetails();
    }, [assetId]);

    useEffect(() => {
        if (!portfolioAsset) {
            return;
        }
        const fetchAsset = () => {
            api.get(`/assets/${portfolioAsset.asset_name}/`)
                .then(response => {
                    setAsset(response.data);
                })
                .catch(error => {
                    console.error('Erreur lors de la récupération de l\'image de l\'actif', error);
                });
        }

        fetchAsset()

    }, [portfolioAsset])

    const handleBackClick = () => {
        navigate(-1);
    };

    return (portfolioAsset &&
        <div className="min-h-screen bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <div className="flex justify-between mb-4">
                    <button onClick={handleBackClick} className="flex items-center text-yellow-500 hover:text-yellow-400">
                        <FontAwesomeIcon icon={faArrowLeft} size="2x" className="mr-2" />
                        <span>Retour</span>
                    </button>
                </div>

                {asset && (
                    <>
                        <div className="bg-gray-900 p-6 rounded-lg shadow-lg">
                            {/* Encart affichant les informations de l'actif */}
                            <AssetBanner asset={asset} hideAmounts={hideAmounts} portfolioAsset={portfolioAsset}/>

                            <div className="mb-6">
                                <h3 className="text-xl text-white mb-4">Historique des valeurs</h3>
                                {valueHistory.length > 0 ? (
                                    <TotalValueOverTime data={valueHistory} hideAmounts={hideAmounts} />
                                ) : (
                                    <p className="text-gray-400">Aucun historique de valeur disponible.</p>
                                )}
                            </div>

                            <div className="mb-6">
                                <h3 className="text-xl text-white mb-4">Profits Réalisés</h3>
                                {profits.length > 0 ? (
                                    <ul className="space-y-2">
                                        {profits.map(profit => (
                                            <li key={profit.id} className="text-gray-300">
                                                {new Date(profit.date).toLocaleString()}: <span className={profit.amount < 0 ? "text-red-500" : "text-green-500"}>{formatAmount(profit.amount, hideAmounts)}</span> - {profit.description}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p className="text-gray-400">Aucun profit réalisé disponible.</p>
                                )}
                            </div>

                            <div className="mt-6">
                                <h3 className="text-xl text-white mb-4">Transactions</h3>
                                {transactions.length > 0 ? (
                                    <div className="space-y-4">
                                        {transactions.map(transaction => (
                                            <TransactionBanner key={transaction.id} transaction={transaction} hideAmounts={hideAmounts} />
                                        ))}
                                    </div>
                                ) : (
                                    <p className="text-gray-400">Aucune transaction disponible.</p>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default AssetDetail;
