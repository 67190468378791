import React, { useState, useEffect } from 'react';
import {formatAmount} from "../../utils";

const UpdateAssetValue = ({ asset, onClose, onUpdate, transactionType }) => {
    const [quantity, setQuantity] = useState(transactionType === 'buy' ? 1 : 0);
    const [unitValue, setUnitValue] = useState(asset.unit_value || 0);
    const [totalValue, setTotalValue] = useState(unitValue * quantity);
    const [dateTime, setDateTime] = useState(new Date().toISOString().slice(0, 16)); // Format YYYY-MM-DDTHH:MM

    useEffect(() => {
        setTotalValue(parseFloat(quantity) * parseFloat(unitValue));
    }, [quantity, unitValue]);

    const handleQuantityChange = (e) => {
        const newQuantity = transactionType === 'sell'
            ? (parseFloat(e.target.value) > parseFloat(asset.quantity) ? asset.quantity : e.target.value)
            : e.target.value;
        setQuantity(newQuantity);
        setTotalValue(newQuantity * unitValue);
    };

    const handleUnitValueChange = (e) => {
        const newUnitValue = e.target.value;
        setUnitValue(newUnitValue);
        setTotalValue(quantity * newUnitValue);
    };

    const handleTotalValueChange = (e) => {
        const newTotalValue = e.target.value;
        setTotalValue(newTotalValue);
        if (quantity !== 0) {
            setUnitValue(newTotalValue / quantity);
        }
    };

    const handleSubmit = () => {
        const totalCurrentValue = quantity * unitValue;
        const initialBenefitsPercentage = 0;
        const investedAmount = parseFloat(asset.value) + totalCurrentValue;

        onUpdate(
            asset.id,
            quantity,
            dateTime,
            unitValue,
            totalCurrentValue,
            initialBenefitsPercentage,
            investedAmount,
            transactionType
        );
    };

    const fillCurrentQuantity = () => {
        setQuantity(parseFloat(asset.quantity));
        setTotalValue(asset.quantity * unitValue);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-black bg-opacity-50"></div>
            <div className="bg-gray-900 rounded-lg shadow-lg p-6 z-10 w-full max-w-md mx-auto">
                {/* Section affichant les informations de l'actif avec une image */}
                <div className="bg-gray-800 p-4 rounded-lg mb-4 flex items-center">
                    {asset.image_url && (
                        <img
                            src={asset.image_url}
                            alt={asset.name}
                            className="w-12 h-12 object-cover rounded-full mr-4"
                        />
                    )}
                    <div>
                        <h3 className="text-lg text-white font-bold">Actif: {asset.name}</h3>
                        <p className="text-gray-400">Quantité disponible: {parseFloat(asset.quantity).toFixed(2)}</p>
                        <p className="text-gray-400">Valeur actuelle: {formatAmount(asset.value)}</p>
                    </div>
                </div>

                <h2 className="text-2xl text-white mb-4">Nouvelle transaction</h2>

                <div className="mb-4">
                    <label className="block text-white mb-2">Quantité</label>
                    <div className="flex">
                        <input
                            type="number"
                            className="w-full p-2 rounded bg-gray-800 text-white"
                            value={quantity}
                            onChange={handleQuantityChange}
                        />
                        {transactionType === 'sell' && (
                            <button
                                onClick={fillCurrentQuantity}
                                className="ml-2 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-500 transition duration-200"
                            >
                                Tout
                            </button>
                        )}
                    </div>
                </div>

                <div className="mb-4">
                    <label className="block text-white mb-2">Prix unitaire</label>
                    <input
                        type="number"
                        className="w-full p-2 rounded bg-gray-800 text-white"
                        value={unitValue}
                        onChange={handleUnitValueChange}
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-white mb-2">Montant total</label>
                    <input
                        type="number"
                        className="w-full p-2 rounded bg-gray-800 text-white"
                        value={totalValue}
                        onChange={handleTotalValueChange}
                    />
                </div>

                {/*<div className="mb-4">*/}
                {/*    <label className="block text-white mb-2">Date et heure</label>*/}
                {/*    <input*/}
                {/*        type="datetime-local"*/}
                {/*        className="w-full p-2 rounded bg-gray-800 text-white"*/}
                {/*        value={dateTime}*/}
                {/*        onChange={(e) => setDateTime(e.target.value)}*/}
                {/*    />*/}
                {/*</div>*/}

                <div className="flex justify-end space-x-4">
                    <button
                        onClick={onClose}
                        className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-400 transition duration-200"
                    >
                        Annuler
                    </button>
                    <button
                        onClick={handleSubmit}
                        className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-500 transition duration-200"
                    >
                        Confirmer
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UpdateAssetValue;
