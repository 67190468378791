// src/pages/Settings.js

import React from 'react';

const Settings = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-black text-white">
            <div className="p-6 bg-gray-900 rounded-lg shadow-lg text-center">
                <h1 className="text-4xl font-bold mb-6 text-yellow-500">Réglages</h1>
                <p className="text-lg mb-4">Cette page est en cours de développement.</p>
                <div className="mt-4 p-4 bg-gray-800 rounded-lg shadow-md">
                    <p className="text-gray-300 mb-2">Nous travaillons dur pour vous offrir une expérience améliorée.</p>
                    <p className="text-gray-300">Revenez bientôt pour voir les mises à jour!</p>
                </div>
            </div>
        </div>
    );
};

export default Settings;
