import React, { useState, useEffect } from 'react';
import api from '../../api';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

function UpdatePortfolio() {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [liquidity, setLiquidity] = useState(true);
    const [date, setDate] = useState('');
    const [mode, setMode] = useState('simple'); // État pour la sélection du mode
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await api.get(`/portfolios/${id}/`);
                const portfolio = response.data;
                setName(portfolio.name);
                setDescription(portfolio.description);
                setLiquidity(portfolio.liquidity);
                setDate(portfolio.latest_date || new Date().toISOString().slice(0, 16));
                setMode(portfolio.mode); // Définit le mode à partir de la réponse de l'API
            } catch (error) {
                console.error('Erreur lors de la récupération du portefeuille', error);
                alert('Erreur lors de la récupération du portefeuille');
            }
        }
        fetchData();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        formData.append('liquidity', liquidity);
        formData.append('date', date);
        formData.append('mode', mode); // Inclure le mode dans la soumission du formulaire

        try {
            await api.patch(`/portfolios/${id}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            // Rediriger vers l'historique du portefeuille après la mise à jour
            navigate(`/portfolio-history/${id}`);
        } catch (error) {
            console.error(error);
            alert('Erreur lors de la mise à jour du portefeuille');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white">
            <div className="max-w-md w-full bg-gradient-to-r from-gray-900 via-gray-800 to-black p-8 rounded-lg shadow-lg border border-yellow-400">
                <h2 className="text-4xl font-bold text-yellow-500 mb-6">Mettre à jour le Portefeuille</h2>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="relative">
                        <label className="block text-yellow-500 text-lg font-bold mb-2" htmlFor="name">
                            Nom du portefeuille
                        </label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="w-full px-4 py-2 bg-gray-800 border rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                            required
                        />
                    </div>
                    <div className="relative">
                        <label className="block text-yellow-500 text-lg font-bold mb-2" htmlFor="description">
                            Description
                        </label>
                        <textarea
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="w-full px-4 py-2 bg-gray-800 border rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                        />
                    </div>
                    <div className="relative">
                        <label className="block text-yellow-500 text-lg font-bold mb-2" htmlFor="date">
                            Date
                        </label>
                        <input
                            type="datetime-local"
                            id="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            className="w-full px-4 py-2 bg-gray-800 border rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                            required
                        />
                    </div>
                    <div className="relative flex items-center mb-4">
                        <input
                            type="checkbox"
                            checked={liquidity}
                            onChange={(e) => setLiquidity(e.target.checked)}
                            className="form-checkbox h-5 w-5 text-yellow-500"
                        />
                        <label className="ml-2 text-yellow-500 text-lg font-bold">
                            Liquide
                        </label>
                    </div>
                    <div className="flex justify-between items-center mb-4">
                        <div
                            onClick={() => setMode('simple')}
                            className={`cursor-pointer p-4 w-1/2 bg-gray-800 rounded-lg shadow-md hover:bg-gray-700 transition duration-300 ease-in-out ${mode === 'simple' ? 'border border-yellow-500' : ''}`}
                        >
                            <h3 className="text-xl font-bold text-yellow-500 mb-2">Simple</h3>
                            <p className="text-gray-300">Fonctionnalités de base</p>
                            <FontAwesomeIcon icon={faCheckCircle} className="text-yellow-500 mt-2" size="2x" />
                        </div>
                        <div
                            onClick={() => setMode('advanced')}
                            className={`cursor-pointer p-4 w-1/2 bg-gray-800 rounded-lg shadow-md hover:bg-gray-700 transition duration-300 ease-in-out ${mode === 'advanced' ? 'border border-yellow-500' : ''}`}
                        >
                            <h3 className="text-xl font-bold text-yellow-500 mb-2">Avancé</h3>
                            <p className="text-gray-300">Pour utilisateurs avancés</p>
                            <FontAwesomeIcon icon={faTimesCircle} className="text-yellow-500 mt-2" size="2x" />
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="w-full py-3 bg-yellow-500 text-black text-lg font-semibold rounded-lg shadow-md hover:bg-yellow-600 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                    >
                        Mettre à jour
                    </button>
                </form>
            </div>
        </div>
    );
}

export default UpdatePortfolio;
