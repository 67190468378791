import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// Fonction pour ajouter les séparateurs de milliers
const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

// Fonction pour générer une série de dates entre deux dates
const generateDateRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1); // Incrémente de 1 jour
    }
    return dates;
};

// Fonction pour récupérer la taille de la fenêtre
const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
};

const MegaStyledHighcharts = ({ data, hideAmounts }) => {
    const size = useWindowSize();
    const isMobile = size.width < 768; // Détecte si la fenêtre est mobile
    const [filteredData, setFilteredData] = useState(data); // Données filtrées selon la période choisie
    const [timeRange, setTimeRange] = useState('all'); // Période sélectionnée

    useEffect(() => {
        const now = new Date();
        const date = new Date();
        let startDate;

        switch (timeRange) {
            case '1d':
                startDate = new Date(date.setDate(date.getDate() - 1));
                break;
            case '1w':
                startDate = new Date(date.setDate(date.getDate() - 7));
                break;
            case '1m':
                startDate = new Date(date.setMonth(date.getMonth() - 1));
                break;
            case '1y':
                startDate = new Date(date.setFullYear(date.getFullYear() - 1));
                break;
            default:
                setFilteredData(data);
                return;
        }

        // Filtrer les données pour correspondre à la plage de temps sélectionnée
        let filtered = data.filter((item) => new Date(item.date).getTime() >= startDate.getTime());

        // Ajouter un point à 0 si les données commencent après la plage sélectionnée
        if (filtered.length > 0 && new Date(filtered[0].date).getTime() > startDate.getTime()) {
            filtered = [{ date: startDate, value: 0, benefitsPercentage: 0 }, ...filtered];
        }

        // Générer une série de dates pour l'axe X
        const dateRange = generateDateRange(startDate, now);

        // Ajouter les dates manquantes dans les données
        const dataWithAllDates = dateRange.map((date) => {
            const existingData = filtered.find((item) => new Date(item.date).getTime() === date.getTime());

            if (existingData) {
                return existingData;
            }

            // Si c'est le dernier point de la série, ne pas utiliser la dernière valeur connue
            const isLastPoint = date.getTime() === now.getTime();
            if (isLastPoint && filtered.length > 0) {
                return filtered[filtered.length - 1]; // Dernière vraie valeur
            }

            // Sinon, utiliser la dernière valeur connue pour combler les trous
            const lastKnownData = { value: 0, benefitsPercentage: 0 };
            return { ...lastKnownData, date };
        });

        setFilteredData(dataWithAllDates);
    }, [timeRange, data]);

    // Transformation des données pour Highcharts
    const transformedData = filteredData.map((item) => {
        const value = parseFloat(item.value);
        const benefitsPercentage = parseFloat(item.benefitsPercentage);
        const valueBeforeBenefits = value / (1 + benefitsPercentage / 100);
        return {
            date: new Date(item.date).toLocaleDateString('fr-FR'),
            value,
            valueBeforeBenefits,
        };
    });

    // Détermine l'intervalle entre les ticks en fonction de la période
    const getTickInterval = () => {
        switch (timeRange) {
            case '1d':
                return 1;  // Chaque jour pour 1 jour
            case '1w':
                return 1;  // Chaque jour pour 1 semaine
            case '1m':
                return 7;  // Chaque 7 jours pour 1 mois
            case '1y':
                return 30; // Chaque mois pour 1 an
            default:
                return null; // Automatique pour "tout"
        }
    };

    const options = {
        chart: {
            type: 'spline',  // Utilisation de spline pour lisser les courbes
            height: isMobile ? 250 : 400,
            backgroundColor: 'transparent',  // Suppression du fond
        },
        title: {
            text: null,  // Suppression du titre
        },
        xAxis: {
            categories: transformedData.map(item => item.date),
            lineColor: '#ccc',  // Ligne X discrète
            tickInterval: getTickInterval(),  // Détermine l'espacement entre les ticks
            labels: {
                style: {
                    color: '#FFFFFF',  // Éclaircir la couleur des labels
                }
            },
            startOnTick: false,  // Empêche de coller aux ticks
            endOnTick: false,    // Empêche de coller aux ticks
            minPadding: 0,       // Suppression de la marge à gauche
            maxPadding: 0,       // Suppression de la marge à droite
            min: 0,              // Fixer le premier point à gauche
            max: transformedData.length - 1,  // Fixer le dernier point à droite
        },
        yAxis: {
            title: {
                text: null,  // Suppression du titre de l'axe Y
            },
            labels: {
                formatter: function () {
                    return hideAmounts ? '' : formatNumber(this.value);
                },
                style: {
                    color: '#FFFFFF',  // Éclaircir la couleur des labels
                }
            },
            gridLineColor: 'rgba(200, 200, 200, 0.2)',  // Grille discrète
        },
        series: [
            {
                name: 'Montant actuel',
                data: transformedData.map(item => item.value),
                color: '#1E90FF',
                lineWidth: 3,
                marker: {
                    enabled: false  // Désactivation des points sur le graphe
                },
            },
            {
                name: 'Montant investi',
                data: transformedData.map(item => item.valueBeforeBenefits),
                color: '#A5D6A7',
                lineWidth: 3,
                marker: {
                    enabled: false  // Désactivation des points sur le graphe
                },
            },
        ],
        tooltip: {
            shared: true,  // Afficher les deux séries dans la tooltip
            backgroundColor: 'rgba(0, 0, 0, 0.9)',  // Fond foncé pour la tooltip
            borderColor: '#333',
            borderRadius: 5,
            style: {
                color: '#FFFFFF',  // Texte blanc dans la tooltip
                fontSize: '12px',
            },
            formatter: function () {
                return `
                    <b>${this.x}</b><br/>
                    <span style="color:#1E90FF">●</span> Montant actuel : ${formatNumber(this.points[0].y.toFixed(2))} €<br/>
                    <span style="color:#A5D6A7">●</span> Montant investi : ${formatNumber(this.points[1].y.toFixed(2))} €`;
            }
        },
        legend: {
            enabled: false,  // Suppression de la légende
        },
        credits: {
            enabled: false  // Suppression de la mention "highcharts.com"
        },
    };

    return (
        <div className="w-full">
            {/* Choix de la période */}
            <div className="flex justify-end space-x-2 mb-4">
                <button
                    onClick={() => setTimeRange('1d')}
                    className={`px-3 py-1 rounded ${timeRange === '1d' ? 'bg-yellow-500 text-black' : 'bg-gray-700 text-white'}`}
                >
                    1J
                </button>
                <button
                    onClick={() => setTimeRange('1w')}
                    className={`px-3 py-1 rounded ${timeRange === '1w' ? 'bg-yellow-500 text-black' : 'bg-gray-700 text-white'}`}
                >
                    1S
                </button>
                <button
                    onClick={() => setTimeRange('1m')}
                    className={`px-3 py-1 rounded ${timeRange === '1m' ? 'bg-yellow-500 text-black' : 'bg-gray-700 text-white'}`}
                >
                    1M
                </button>
                <button
                    onClick={() => setTimeRange('1y')}
                    className={`px-3 py-1 rounded ${timeRange === '1y' ? 'bg-yellow-500 text-black' : 'bg-gray-700 text-white'}`}
                >
                    1A
                </button>
                <button
                    onClick={() => setTimeRange('all')}
                    className={`px-3 py-1 rounded ${timeRange === 'all' ? 'bg-yellow-500 text-black' : 'bg-gray-700 text-white'}`}
                >
                    Tout
                </button>
            </div>

            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default MegaStyledHighcharts;
