// src/pages/Subscriptions.js

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGem, faCrown, faCheckCircle, faArrowUp, faTimesCircle, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { UserContext } from '../../context/UserContext';
import api from '../../api';
import { NotificationManager } from "react-notifications";

// Initialiser les styles de modal
Modal.setAppElement('#root');

// Export the subscriptions object
export const subscriptions = [
    {
        db_name: 'silver',
        name: 'Silver',
        annual_price: 0.00,  // Gratuit
        benefits: [
            'Création de portefeuilles (1 par type)',
            'Dashboard interactif',
            'Mise à jour manuelle'
        ],
        bgColor: 'bg-gradient-to-r from-gray-300 to-gray-500',
        textColor: 'text-gray-900',
        buttonColor: 'bg-gray-600',
        iconColor: 'text-gray-900',
        icon: faShieldAlt,
    },
    {
        annual_id: process.env.REACT_APP_GOLD_SUBSCRIPTION_ID,  // Annuel
        db_name: 'gold',
        name: 'Or',
        annual_price: 38.99,  // Réduction annuelle
        benefits: [
            'Création de portefeuilles sans limite',
            'Dashboard interactif',
            'Mise à jour automatique tous les jours',
        ],
        bgColor: 'bg-gradient-to-r from-yellow-300 to-yellow-500',
        textColor: 'text-yellow-900',
        buttonColor: 'bg-yellow-600',
        iconColor: 'text-yellow-900',
        icon: faCrown,
    },
    {
        annual_id: process.env.REACT_APP_DIAMOND_SUBSCRIPTION_ID,  // Annuel
        db_name: 'diamond',
        name: 'Diamant',
        annual_price: 59.99,  // Réduction annuelle
        benefits: [
            'Création de portefeuilles sans limite',
            'Dashboard interactif',
            'Mise à jour automatique toutes les heures',
            'Support client dédié 24/7',
            'Accès en exclusivité aux nouvelles fonctionnalités',
        ],
        bgColor: 'bg-gradient-to-r from-indigo-400 via-indigo-500 to-indigo-600',
        textColor: 'text-white',
        buttonColor: 'bg-indigo-700',
        iconColor: 'text-white',
        icon: faGem,
    },
];

const Subscriptions = () => {
    const navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);
    const [currentSubscription, setCurrentSubscription] = useState('silver'); // 'silver' par défaut
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        if (user && user.subscription_type) {
            setCurrentSubscription(user.subscription_type);
        }
    }, [user]);

    const handleSubscribe = (subscriptionId, subscriptionName) => {
        const priceId = subscriptionId.annual_id; // Utiliser uniquement le prix annuel si disponible
        navigate('/checkout', { state: { subscriptionId: priceId, subscriptionName } });
    };

    const handleCancelSubscription = async () => {
        try {
            const response = await api.post('/cancel-subscription/', {
                customer_id: user.id,
            });

            const result = response.data;

            if (result.status === 'success') {
                NotificationManager.success('Abonnement annulé avec succès.');
                const userResponse = await api.get('/current-user/');
                localStorage.setItem('user', JSON.stringify(userResponse.data));
                navigate('/')
            } else {
                NotificationManager.error(result.message || 'Échec de l\'annulation de l\'abonnement');
            }
        } catch (error) {
            console.error('Erreur API lors de l\'annulation de l\'abonnement:', error);
            NotificationManager.error('Une erreur est survenue lors du traitement de votre demande.');
        }
    };

    const openCancelModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const confirmCancel = () => {
        handleCancelSubscription();
        closeModal();
    };

    // Filtrer les abonnements en fonction de l'abonnement actuel de l'utilisateur
    const filteredSubscriptions = currentSubscription === 'diamond'
        ? subscriptions.filter(sub => sub.db_name === 'diamond')
        : (currentSubscription === 'gold' ? subscriptions.filter(sub => (sub.db_name === 'gold' || sub.db_name === 'diamond')) : subscriptions);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white py-12">
            <h1 className="text-5xl font-bold mb-12 text-yellow-500">Choisissez votre abonnement</h1>
            {currentSubscription && (
                <div className="text-xl font-semibold mb-8">
                    Vous avez actuellement un abonnement {currentSubscription.charAt(0).toUpperCase() + currentSubscription.slice(1)}.
                </div>
            )}
            <div className={`grid ${filteredSubscriptions.length === 1 ? 'grid-cols-1' : (filteredSubscriptions.length == 2 ? 'grid-cols-1 md:grid-cols-2' : 'grid-cols-1 md:grid-cols-3')} gap-12 px-6 max-w-8xl`}>
                {filteredSubscriptions.map((sub, index) => (
                    <div
                        key={index}
                        className={`p-8 rounded-xl shadow-xl transform transition-transform duration-300 hover:scale-105 ${sub.bgColor} flex flex-col justify-between`}
                    >
                        <div>
                            <h2 className={`text-3xl font-extrabold mb-4 flex items-center ${sub.textColor}`}>
                                <FontAwesomeIcon icon={sub.icon} className="mr-2" />
                                Abonnement {sub.name}
                            </h2>
                            {sub.annual_price > 0 && (
                                <p className={`text-2xl font-bold mb-2 ${sub.textColor}`}>
                                    <span className="line-through mr-2">{(sub.annual_price * 1.5).toFixed(2)} €/an</span>
                                    {sub.annual_price} €/an
                                </p>
                            )}
                            {sub.annual_price === 0 && (
                                <p className={`text-2xl font-bold mb-2 ${sub.textColor}`}>
                                    Gratuit
                                </p>
                            )}
                            {sub.annual_price > 0 && (
                                <p className="text-lg font-bold text-green-500">30 jours d'essai gratuit</p>
                            )}
                            <ul className="mb-8 space-y-4">
                                {sub.benefits.map((benefit, i) => (
                                    <li key={i} className={`text-lg ${sub.textColor} flex items-center`}>
                                        <FontAwesomeIcon icon={faCheckCircle} className={`mr-2 ${sub.iconColor}`} />
                                        {benefit}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {currentSubscription === sub.db_name ? (
                            sub.db_name !== 'silver' && (
                                <button
                                    onClick={openCancelModal}
                                    className={`mt-auto w-full py-3 rounded-lg font-semibold text-white hover:opacity-90 transition-opacity duration-300 bg-red-600 flex items-center justify-center`}
                                >
                                    <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
                                    Résilier
                                </button>
                            )
                        ) : (
                            <button
                                onClick={() => handleSubscribe(sub, sub.db_name)}
                                className={`mt-auto w-full py-3 rounded-lg font-semibold text-white hover:opacity-90 transition-opacity duration-300 ${sub.buttonColor} flex items-center justify-center`}
                            >
                                {currentSubscription !== 'silver' && currentSubscription !== sub.db_name ? (
                                    <>
                                        <FontAwesomeIcon icon={faArrowUp} className="mr-2" />
                                        Mettre à niveau
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                                        S'abonner
                                    </>
                                )}
                            </button>
                        )}
                    </div>
                ))}
            </div>
            {/* Modal de confirmation */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Confirmation"
                className="bg-gradient-to-r from-gray-800 via-gray-900 to-black text-white p-8 rounded-xl shadow-xl max-w-md mx-auto mt-32"
                overlayClassName="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center"
            >
                <h2 className="text-2xl font-bold mb-4">Confirmer la résiliation</h2>
                <p className="mb-8">
                    Êtes-vous sûr de vouloir résilier votre abonnement ?
                </p>
                <div className="flex justify-end">
                    <button
                        onClick={closeModal}
                        className="bg-gray-600 text-white px-4 py-2 rounded mr-4 hover:bg-gray-700 transition"
                    >
                        Annuler
                    </button>
                    <button
                        onClick={confirmCancel}
                        className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition"
                    >
                        Confirmer
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default Subscriptions;
