import React, { useState, useEffect, useContext } from 'react';
import api from '../../api';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Unauthorized from "../Unauthorized";
import Loader from "../Loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { UserContext } from "../../context/UserContext";
import AssetList from '../assets/AssetList';
import DeletePortfolioModal from './DeletePortfolioModal';
import PortfolioHeader from './PortfolioHeader';

Modal.setAppElement('#root');

const PortfolioHistory = ({ assetCategories }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [history, setHistory] = useState([]);
    const [profitsHistory, setProfitsHistory] = useState([]);
    const [name, setName] = useState('');
    const [value, setValue] = useState(0);
    const [benefitsPercentage, setBenefitsPercentage] = useState(0);
    const [description, setDescription] = useState('');
    const [benefits, setBenefits] = useState(0);
    const [unauthorized, setUnauthorized] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [image, setImage] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [assets, setAssets] = useState([]);
    const [category, setCategory] = useState('');
    const { preferences, getAssetCategoriesSummary } = useContext(UserContext);
    const { hideAmounts } = preferences;

    useEffect(() => {
        fetchData();
    }, [id]);
    const fetchData = async () => {
        try {
            const response = await api.get(`/portfolios/${id}/`);
            const portfolio = response.data;
            setName(portfolio.name);
            setDescription(portfolio.description);
            setImage(portfolio.image ?? '/default.jpg');
            setCategory(portfolio.category);

            await fetchHistory(); // Récupérer l'historique initial

            if (portfolio.mode === 'advanced') {
                await fetchAssets();
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données', error);
            if (error.response && error.response.status === 403) {
                setUnauthorized(true);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const fetchHistory = async () => {
        try {
            const response = await api.get(`/portfolios/${id}/history/`);
            const sortedDataHistory = response.data.history.sort((a, b) => new Date(a.date) - new Date(b.date));
            setValue(response.data.last_value);
            setBenefitsPercentage(response.data.last_benefits_percentage);
            setBenefits(response.data.last_benefits);
            setHistory(sortedDataHistory);
            getAssetCategoriesSummary()
        } catch (error) {
            console.error('Erreur lors de la récupération de l\'historique', error);
        }
    };

    const fetchAssets = async () => {
        try {
            const response = await api.get(`/portfolios/${id}/assets/`);
            const fetchedAssets = response.data.assets;
            setAssets(fetchedAssets);
        } catch (error) {
            console.error('Erreur lors de la récupération des actifs', error);
        }
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const openDeleteModal = () => {
        setDeleteModalIsOpen(true);
    };

    const closeDeleteModal = () => {
        setDeleteModalIsOpen(false);
    };

    const openModal = async () => {
        try {
            const response = await api.get('/portfolios/images/');
            setImages(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des images', error);
        }
        setModalIsOpen(true);
    };

    const handleImageSelect = async (selectedImage) => {
        const formData = new FormData();
        formData.append('image', selectedImage);

        try {
            const response = await api.patch(`/portfolios/${id}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setImage(response.data.image);
            closeModal();
        } catch (error) {
            console.error('Erreur lors de la mise à jour de l\'image', error);
        }
    };

    const handleDelete = async () => {
        try {
            await api.delete(`/portfolios/${id}/`);
            navigate('/');
        } catch (error) {
            console.error('Erreur lors de la suppression du portefeuille', error);
        }
    };

    const handleAddAsset = () => {
        navigate(`/create-asset/${id}`, { state: { category, assets } });
    };

    if (isLoading) {
        return <Loader />;
    }

    if (unauthorized) {
        return <Unauthorized />;
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <div className="flex justify-between mb-4">
                    <Link to={`/portfolio-list/${category}`} className="flex items-center text-yellow-500 hover:text-yellow-400">
                        <FontAwesomeIcon icon={faArrowLeft} size="2x" className="mr-2"/>
                        <span>Retour</span>
                    </Link>
                    <button
                        onClick={openDeleteModal}
                        className="flex items-center bg-red-600 text-white text-lg py-2 px-4 rounded shadow hover:bg-red-700 transition duration-300 ease-in-out">
                        <FontAwesomeIcon icon={faTrash} className="mr-2" />
                    </button>
                </div>
                <PortfolioHeader
                    id={id}
                    name={name}
                    description={description}
                    value={value}
                    benefits={benefits}
                    benefitsPercentage={benefitsPercentage}
                    hideAmounts={hideAmounts}
                    image={image}
                    history={history}
                    categoryLabel={assetCategories.find((c) => c.value === category)?.label}
                    openModal={openModal}
                />
                <AssetList
                    assets={assets}
                    handleAddAsset={handleAddAsset}
                    refreshAssets={fetchAssets}
                    portfolioId={id}
                    hideAmounts={hideAmounts}
                    onAssetChange={fetchHistory}
                />
                <DeletePortfolioModal
                    isOpen={deleteModalIsOpen}
                    onRequestClose={closeDeleteModal}
                    onDelete={handleDelete}
                />
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Sélectionner une image"
                    className="fixed inset-0 flex items-center justify-center"
                    overlayClassName="fixed inset-0 bg-black bg-opacity-50"
                >
                    <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-full max-w-2xl mx-auto">
                        <h2 className="text-2xl font-bold mb-4 text-yellow-500">Sélectionner une image</h2>
                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                            {images.map((img, index) => (
                                <div key={index} className="cursor-pointer" onClick={() => handleImageSelect(img)}>
                                    <img src={img} alt={`image-${index}`} className="h-32 w-32 object-cover rounded-md shadow-md mx-auto" />
                                </div>
                            ))}
                        </div>
                        <button onClick={closeModal} className="mt-4 bg-red-600 text-white text-lg py-2 px-4 rounded shadow hover:bg-red-700">
                            Fermer
                        </button>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default PortfolioHistory;
