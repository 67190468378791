import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faEdit } from '@fortawesome/free-solid-svg-icons';
import UpdateAssetValue from './UpdateAssetValue';
import UpdateAssetBenefits from './UpdateAssetBenefits'; // Importez le nouveau composant de mise à jour des bénéfices
import api from '../../api';
import {formatAmount, printAmountWithBenefits} from "../../utils";
import { useNavigate } from 'react-router-dom';

const AssetList = ({ assets, onAssetChange, refreshAssets, portfolioId, handleAddAsset, hideAmounts }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isBenefitsModalOpen, setIsBenefitsModalOpen] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [transactionType, setTransactionType] = useState(null);
    const navigate = useNavigate();
    const closeModal = () => {
        setSelectedAsset(null);
        setIsModalOpen(false);
    };

    const closeBenefitsModal = () => {
        setSelectedAsset(null);
        setIsBenefitsModalOpen(false);
    };

    const openModal = (asset, type) => {
        setSelectedAsset(asset);
        setTransactionType(type);
        setIsModalOpen(true);
    };

    const openBenefitsModal = (asset) => {
        setSelectedAsset(asset);
        setIsBenefitsModalOpen(true);
    };

    const handleCardClick = (asset) => {
        navigate(`/assets/${asset.id}`, { state: { asset } });
    };

    const handleUpdateAsset = async (assetId, quantity, date, unitValue, totalCurrentValue, initialBenefitsPercentage, investedAmount) => {
        try {
            const payload = {
                portfolio_id: portfolioId,
                asset_name_id: selectedAsset.asset_id,
                quantity,
                date,
                unit_value: unitValue,
                value: totalCurrentValue,
                benefitsPercentage: initialBenefitsPercentage,
                investedAmount,
                transactionType,
            };

            await api.post(`/create-transaction/`, payload);

            console.log('Asset updated');
            refreshAssets();
            onAssetChange();
            closeModal();
        } catch (error) {
            console.error('Error updating asset:', error);
        }
    };

    const handleUpdateBenefits = async (assetId, benefitsPercentage) => {
        try {
            const payload = {
                benefitsPercentage
            };

            await api.put(`/portfolio-assets/${assetId}/update-benefits-percentage/`, payload);

            console.log('Benefits updated');
            refreshAssets();
            onAssetChange();
            closeBenefitsModal();
        } catch (error) {
            console.error('Error updating benefits:', error);
        }
    };

    // Trier les actifs par montant (value) décroissant
    const sortedAssets = assets.sort((a, b) => b.value - a.value);

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-4">
            {/* Carte vide pour ajouter un nouvel actif */}
            <div
                className="bg-gray-800 rounded-lg shadow-lg p-6 flex flex-col justify-center items-center cursor-pointer transition duration-200 transform hover:-translate-y-1 hover:shadow-lg hover:bg-gray-700"
                onClick={handleAddAsset}
            >
                <FontAwesomeIcon icon={faPlus} className="text-white text-4xl mb-4" />
                <p className="text-white text-center">Ajouter un nouvel actif</p>
            </div>

            {sortedAssets.map(asset => (
                <div key={asset.id} className="bg-gray-900 rounded-lg shadow-lg p-6 flex flex-col justify-between relative cursor-pointer" onClick={() => handleCardClick(asset)}>
                    <div className="flex items-center space-x-4">
                        {asset.image_url && (
                            <img src={asset.image_url} alt={asset.name} className="h-12 w-12 rounded-full" />
                        )}
                        <div>
                            <h2 className="text-lg font-semibold text-white">{asset.name}</h2>
                            <p className="text-sm text-gray-400">Dernière mise à jour: {asset.last_updated ? (new Date(asset.last_updated).toLocaleString()) : ''}</p>
                        </div>
                    </div>
                    <div className="mt-4">
                        <p className="text-sm text-gray-400"><span className="text-white">{printAmountWithBenefits(asset.value, parseFloat(asset.value * (1 - 1 / (1 + (asset.benefitsPercentage / 100)))), parseFloat(asset.benefitsPercentage), hideAmounts)}</span></p>
                        {/*make the profits red or green depending on operand*/}
                        <p className="text-sm text-gray-400">Profits réalisés : <span className={asset.profits_realized < 0 ? "text-red-500" : "text-green-500"}>{formatAmount(asset.profits_realized ?? 0, hideAmounts)}</span></p>
                        <p className="text-sm text-gray-400">Quantité: <span className="text-white">{parseFloat(asset.quantity)}</span></p>
                    </div>
                    <div className="mt-6 flex justify-end space-x-4">
                        <button
                            onClick={(e) => {e.stopPropagation(); openModal(asset, 'sell');}}
                            className="p-2 bg-red-500 text-white rounded-lg shadow hover:bg-red-400 transition duration-200 transform hover:-translate-y-1 hover:shadow-md"
                        >
                            <FontAwesomeIcon icon={faMinus} className="w-4 h-4" />
                        </button>
                        <button
                            onClick={(e) => {e.stopPropagation(); openModal(asset, 'buy');}}
                            className="p-2 bg-green-500 text-white rounded-lg shadow hover:bg-green-400 transition duration-200 transform hover:-translate-y-1 hover:shadow-md"
                        >
                            <FontAwesomeIcon icon={faPlus} className="w-4 h-4" />
                        </button>
                        {/* Ajouter le bouton de modification */}
                        <button
                            onClick={(e) => {e.stopPropagation(); openBenefitsModal(asset);}}
                            className="p-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-400 transition duration-200 transform hover:-translate-y-1 hover:shadow-md absolute bottom-6 left-6"
                        >
                            <FontAwesomeIcon icon={faEdit} className="w-4 h-4" />
                        </button>
                    </div>
                </div>
            ))}

            {isModalOpen && (
                <UpdateAssetValue
                    asset={selectedAsset}
                    transactionType={transactionType}
                    onClose={closeModal}
                    onUpdate={handleUpdateAsset}
                />
            )}

            {isBenefitsModalOpen && (
                <UpdateAssetBenefits
                    asset={selectedAsset}
                    onClose={closeBenefitsModal}
                    onUpdate={handleUpdateBenefits}
                />
            )}
        </div>
    );
};

export default AssetList;
