// context/UserContext.js

import React, { createContext, useState, useEffect } from 'react';
import api from '../api';
import Cookies from 'js-cookie';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [preferences, setPreferences] = useState({
        hideAmounts: true,
        cookies: {
            essential: true,
            analytics: false,
            marketing: false,
        },
    });
    const [assetCategoriesSummary, setAssetCategoriesSummary] = useState({});  // Ajoutez cet état

    const getAssetCategoriesSummary = async () => {
        const [
            assetCategoriesSummaryResponse,
        ] = await Promise.all([
            api.get('/asset-categories-summary/'),
        ]);

        setAssetCategoriesSummary(assetCategoriesSummaryResponse.data);
    }

    const toggleHideAmounts = () => {
        setPreferences(prevPreferences => {
            const newPreferences = {
                ...prevPreferences,
                hideAmounts: !prevPreferences.hideAmounts,
            };
            localStorage.setItem('hideAmounts', JSON.stringify(newPreferences.hideAmounts));
            return newPreferences;
        });
    };

    const saveCookiePreferences = (prefs) => {
        setPreferences(prevPreferences => ({
            ...prevPreferences,
            cookies: prefs,
        }));
        Cookies.set('cookie_consent', JSON.stringify(prefs), { expires: 365 });
    };
    useEffect(() => {
        const fetchData = () => {
            try {
                // Load user from localStorage
                const savedUser = localStorage.getItem('user');
                const accessToken = localStorage.getItem('access_token');
                if (savedUser && accessToken) {
                    setUser(JSON.parse(savedUser));
                    api.defaults.headers['Authorization'] = 'Bearer ' + accessToken;
                    getAssetCategoriesSummary()
                }

                // Load preferences from localStorage
                const savedHideAmounts = localStorage.getItem('hideAmounts');
                if (savedHideAmounts !== null) {
                    setPreferences(prev => ({
                        ...prev,
                        hideAmounts: JSON.parse(savedHideAmounts),
                    }));
                }

                // Load cookie preferences from cookies
                const consentGiven = Cookies.get('cookie_consent');
                if (consentGiven) {
                    setPreferences(prev => ({
                        ...prev,
                        cookies: JSON.parse(consentGiven),
                    }));
                }

            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };

        fetchData();
    }, [setUser, setPreferences, setAssetCategoriesSummary]);

    const fetchUser = async () => {
        const userResponse = await api.get('/current-user/');
        localStorage.setItem('user', JSON.stringify(userResponse.data));
        setUser(userResponse.data);
    }
    return (
        <UserContext.Provider value={{ user, setUser, preferences, toggleHideAmounts, saveCookiePreferences, assetCategoriesSummary, getAssetCategoriesSummary, fetchUser }}>
            {children}
        </UserContext.Provider>
    );
};
