import React from 'react';
import { Circles } from 'react-loader-spinner'; // Import du loader Circles

const LoadingPage = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gradient-to-br from-gray-800 via-black to-gray-900 text-white">
            <div className="flex justify-center items-center">
                <Circles
                    height="80"
                    width="80"
                    color="#facc15"  // Garder la couleur cohérente avec tes autres pages
                    ariaLabel="circles-loading"
                    visible={true}
                />
            </div>
            <p className="mt-4 text-lg font-bold text-yellow-500">Chargement en cours, merci de patienter...</p>
        </div>
    );
};

export default LoadingPage;
