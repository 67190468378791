import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import api from '../api';

const RegistrationConfirmation = () => {
    const [code, setCode] = useState(new Array(6).fill(''));
    const [userId, setUserId] = useState(localStorage.getItem('user_id') || ''); // Assuming user_id is stored in localStorage
    const navigate = useNavigate();
    const inputRefs = useRef([]);

    const handleChange = (element, index) => {
        const value = element.value;
        if (/^\d*$/.test(value)) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            if (value !== '' && index < 5) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const confirmationCode = code.join('');
        try {
            const response = await api.post('/verify-confirmation-code/', { user_id: userId, code: confirmationCode });
            NotificationManager.success('Votre compte a été activé avec succès.');
            navigate('/');
        } catch (error) {
            NotificationManager.error('Code de confirmation invalide.');
            console.error('Erreur lors de la vérification du code de confirmation', error);
        }
    };

    const handleResendEmail = async () => {
        try {
            const response = await api.post('/resend-confirmation-email/', { user_id: userId });
            NotificationManager.success('E-mail de confirmation renvoyé.');
        } catch (error) {
            NotificationManager.error('Erreur lors du renvoi de l\'e-mail de confirmation.');
            console.error('Erreur lors du renvoi de l\'e-mail de confirmation', error);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white">
            <div className="max-w-md w-full bg-gradient-to-r from-gray-900 via-gray-800 to-black p-8 rounded-lg shadow-lg border border-yellow-400 text-center">
                <h2 className="text-2xl font-bold text-yellow-500 mb-4">Inscription réussie</h2>
                <p className="text-gray-300 mb-6">Veuillez vérifier votre e-mail pour activer votre compte.</p>
                <p className="text-gray-300 mb-6">Entrez le code de confirmation que vous avez reçu par e-mail :</p>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="flex justify-center space-x-2">
                        {code.map((digit, index) => (
                            <input
                                key={index}
                                type="text"
                                maxLength="1"
                                value={digit}
                                onChange={(e) => handleChange(e.target, index)}
                                className="w-12 h-12 text-center text-xl bg-gray-800 border border-gray-300 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                                ref={(el) => (inputRefs.current[index] = el)}
                                required
                            />
                        ))}
                    </div>
                    <button
                        type="submit"
                        className="w-full py-3 bg-yellow-500 text-black text-lg font-semibold rounded-lg shadow-md hover:bg-yellow-600 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                    >
                        Vérifier le code
                    </button>
                </form>
                <button
                    onClick={handleResendEmail}
                    className="w-full mt-4 py-3 bg-yellow-500 text-black text-lg font-semibold rounded-lg shadow-md hover:bg-yellow-600 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                >
                    Renvoyer l'e-mail de confirmation
                </button>
                <p className="text-gray-300 mt-6">Si vous ne voyez pas l'e-mail dans votre boîte de réception, veuillez vérifier votre dossier de spam.</p>
                <div className="mt-6">
                    <a
                        href="/"
                        className="bg-yellow-500 text-black py-2 px-4 rounded hover:bg-yellow-600 transition duration-300"
                    >
                        Retour à l'accueil
                    </a>
                </div>
            </div>
        </div>
    );
};

export default RegistrationConfirmation;
