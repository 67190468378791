import React from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';

const AssetCategoriesRepartition = ({ assetCategoriesSummary, assetCategories, hideAmounts }) => {
    // Préparer les données pour le PieChart
    const pieData = Object.keys(assetCategoriesSummary).map(key => {
        const category = assetCategories.find(c => c.value === key);
        return {
            name: category?.label || key,
            value: assetCategoriesSummary[key].total_value,
            icon: category?.icon,
        };
    });

    const formatNumber = (num) => {
        let formattedNum = num.toFixed(2);
        return formattedNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    const totalValue = pieData.reduce((acc, item) => acc + item.value, 0);

    const COLORS = ['#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#33FFF5', '#FFAF33', '#9933FF'];

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const percentage = ((payload[0].value / totalValue) * 100).toFixed(2);
            return (
                <div style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.85)',
                    border: '1px solid #333',
                    padding: '8px',
                    borderRadius: '5px',
                    color: '#fff',
                    textAlign: 'center',
                }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ marginRight: '6px' }}>{payload[0].payload.icon}</span>
                        <p style={{ margin: 0 }}>{payload[0].name}</p>
                    </div>
                    {!hideAmounts && (
                        <>
                            <p style={{ margin: 0 }}>
                                <strong>{`${formatNumber(payload[0].value)} €`}</strong>
                            </p>
                            <p style={{ margin: 0 }}>
                                <strong>{`${percentage}%`}</strong>
                            </p>
                        </>
                    )}
                </div>
            );
        }

        return null;
    };

    return (
        <ResponsiveContainer width="100%" height={400}>
            <PieChart>
                <Pie
                    data={pieData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={150}
                    innerRadius={70}
                    fill="#8884d8"
                    paddingAngle={2}
                    dataKey="value"
                    animationDuration={800}
                    animationEasing="ease-out"
                    isAnimationActive={false}
                >
                    {pieData.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                            stroke="#000"
                            strokeWidth={1}
                        />
                    ))}
                </Pie>
                <Tooltip content={<CustomTooltip hideAmounts={hideAmounts} />} />
                <Legend
                    align="center"
                    verticalAlign="bottom"
                    layout="horizontal"
                    iconType="circle"
                    iconSize={10}
                    formatter={(value, entry) => (
                        <span style={{ color: '#fff', fontWeight: 'bold', fontSize: '12px' }}>{entry.payload.name}</span>
                    )}
                />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default AssetCategoriesRepartition;
