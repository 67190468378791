export const getLatestValue = (values) => {
    if (values.length === 0) return 0;
    return values.reduce((latest, value) => {
        return new Date(value.date) > new Date(latest.date) ? value : latest;
    }).value;
};

export const printAmountWithBenefits = (amount, benefits, percentage, hideAmounts, size = 4) => {
    const percentageClass = percentage >= 0 ? 'text-green-500' : 'text-red-500';
    const operand = percentage >= 0 ? '+' : '';
    const blurClass = hideAmounts ? 'blur-lg' : '';

    //if mobile size /2
    size = window.innerWidth < 640 ? size/2 : size;

    return (
        <div className={`inline-block ${blurClass}`}>
            <span className={`text-${size}xl font-bold text-yellow-300`}>
                {formatAmount(amount)}
            </span>
            <span className={`text-${size / 2}xl font-bold ${percentageClass} ml-2`}>
                ({operand + formatAmount(benefits)},
            </span>
            <span className={`text-${size / 2}xl font-bold ml-2 ${percentageClass}`}>
                {operand + (percentage).toFixed(2)} %)
            </span>
        </div>
    );
};

export const formatAmount = (amount, hideAmounts = false) => {
    const blurClass = hideAmounts ? 'blur-lg' : '';

    // Vérifie si amount est un nombre valide, sinon renvoie 0
    const validAmount = isNaN(amount) || amount === null ? 0 : amount;

    if (hideAmounts) {
        return (
            <div className={`inline-block ${blurClass}`}>
                {new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(validAmount)}
            </div>
        )
    } else {
        return new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(validAmount);
    }
}
