import React, { useState } from 'react';
import api from '../../../api';
import { useNavigate, useParams } from 'react-router-dom';

function UpdatePortfolioValue() {
    const [value, setValue] = useState('');
    const [benefitsPercentage, setBenefitsPercentage] = useState(0);
    const [date, setDate] = useState(new Date().toISOString().slice(0, 16));
    const navigate = useNavigate();
    const { id } = useParams();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await api.post(`/portfolio-values/`, { portfolio: id, value, benefitsPercentage, date });
            navigate('/');
        } catch (error) {
            console.error(error);
            alert('Erreur lors de la mise à jour du portefeuille');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white">
            <div className="max-w-md w-full bg-gradient-to-r from-gray-900 via-gray-800 to-black p-8 rounded-lg shadow-lg border border-yellow-400">
                <h2 className="text-4xl font-bold text-yellow-500 mb-6">Mettre à jour le Portefeuille</h2>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="relative">
                        <label className="block text-yellow-500 text-lg font-bold mb-2" htmlFor="value">
                            Nouvelle Valeur
                        </label>
                        <input
                            type="number"
                            id="value"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            className="w-full px-4 py-2 bg-gray-800 border rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                            required
                        />
                    </div>
                    <div className="relative">
                        <label className="block text-yellow-500 text-lg font-bold mb-2" htmlFor="benefitsPercentage">
                            Pourcentage de bénéfices
                        </label>
                        <input
                            type="number"
                            id="benefitsPercentage"
                            value={benefitsPercentage}
                            onChange={(e) => setBenefitsPercentage(e.target.value)}
                            className="w-full px-4 py-2 bg-gray-800 border rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                        />
                    </div>
                    <div className="relative">
                        <label className="block text-yellow-500 text-lg font-bold mb-2" htmlFor="date">
                            Date
                        </label>
                        <input
                            type="datetime-local"
                            id="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            className="w-full px-4 py-2 bg-gray-800 border rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full py-3 bg-yellow-500 text-black text-lg font-semibold rounded-lg shadow-md hover:bg-yellow-600 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                    >
                        Mettre à jour
                    </button>
                </form>
            </div>
        </div>
    );
}

export default UpdatePortfolioValue;
